import { Flex } from "@/ui/antd";
import { Button } from "@/ui/Button";
import { ResponsiveModal } from "@/ui/ResponsiveModal";
import { FC, ReactNode } from "react";

export const ConfirmationModal: FC<{
	header: ReactNode;
	onSuccess: () => void;
	onClose: () => void;
	open: boolean;
	children: ReactNode;
	confirmText: string;
}> = ({ header, onSuccess, onClose, open, confirmText, children }) => {
	return (
		<ResponsiveModal
			open={open}
			header={header}
			onCancel={onClose}
			width="620px"
			footer={
				<Flex justify="space-between" wide>
					<Button aria-label="Cancel" variant="tertiary" onClick={onClose}>
						Cancel
					</Button>
					<Button aria-label="Continue" variant="danger" onClick={onSuccess}>
						{confirmText}
					</Button>
				</Flex>
			}
		>
			{children}
		</ResponsiveModal>
	);
};
