import { Modal } from "antd";
import { useState } from "react";
import UserService from "../services/UsersService";
import { Form, Input } from "@/ui/antd";
import axios from "../services/AxiosWrapper";
import { Button, ButtonVariant } from "@/ui/Button";
import { LoadingSpinner } from "./AppBlocks";
import ButtonsBar from "./buttons/ButtonsBar";
import { LegacyButton } from "./buttons/LegacyButton";

export const OTPModal = ({
	submitFunction,
	handleSubmit,
	buttonText = "Save",
	buttonVariant = "primary"
}: {
	submitFunction: any;
	handleSubmit?: any;
	buttonText: string;
	buttonVariant: ButtonVariant;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [hasBeenResent, setHasBeenResent] = useState(false);
	const [otpForm] = Form.useForm();

	const openModal = async () => {
		setIsOpen(true);
		setLoading(true);
		await UserService.generateOTP();
		setLoading(false);
	};

	const resendOTP = () => {
		UserService.generateOTP(true);
		setHasBeenResent(true);
	};

	const submit = () => {
		const formValues = otpForm.getFieldsValue(true);
		axios.setOTP(formValues.otp);
		submitFunction();
		setIsOpen(false);
	};

	return (
		<>
			<Button
				onClick={handleSubmit ? handleSubmit(openModal) : openModal}
				key={buttonText}
				htmlType="button"
				variant={buttonVariant}
			>
				{buttonText}
			</Button>
			<Modal
				open={isOpen}
				destroyOnClose={true}
				onCancel={() => setIsOpen(false)}
				okText="Submit"
				title="Enter one time password"
				onOk={submit}
				footer={
					<ButtonsBar footer>
						<LegacyButton
							style={{
								float: "left"
							}}
							key="resend-otp"
							htmlType="button"
							ariaLabel="Resend OTP"
							onClick={resendOTP}
							disabled={hasBeenResent}
						>
							{hasBeenResent ? "Resent" : "Resend OTP"}
						</LegacyButton>
						<LegacyButton key="submit" type="primary" htmlType="submit" ariaLabel="Continue" onClick={submit}>
							Continue
						</LegacyButton>
					</ButtonsBar>
				}
			>
				{loading ? (
					<LoadingSpinner style={{ margin: "16px auto", display: "block" }} />
				) : (
					<div>
						<p>A one time password has been sent to the email associated with this account, please enter it below:</p>
						<Form form={otpForm} onFinish={handleSubmit}>
							<Form.Item name="otp">
								<Input.OTP />
							</Form.Item>
						</Form>
					</div>
				)}
			</Modal>
		</>
	);
};
