import { BankAccounts } from "@hx/utilities";
import { Field } from "redux-form";
import { SelectField } from "@/components/Fields";

let BankAccountOptions = ({ path, bankAccountsList, validate, required }) => {
	const accountOptions = bankAccountsList.map((account) => {
		let accountName = account.name;
		if (account.location === "US") {
			if (account.accountHolderType === "individual") {
				accountName = [account.firstName, account.lastName].join(" ");
			} else if (account.accountHolderType === "business") {
				accountName = account.companyName;
			} else {
				accountName = account.bankName;
			}
		}

		const bankAccountParts = BankAccounts.getDisplayParts(account.bsb, account.number, account.location, accountName);
		return {
			value: account._id,
			label: bankAccountParts.join(", ")
		};
	});

	return (
		<div style={{ width: "100%" }}>
			<Field
				name={path ? `${path}userBankAccountId` : `userBankAccountId`}
				label="Bank account"
				component={SelectField}
				dropdownMatchSelectWidth={false}
				options={accountOptions}
				style={{ width: "100%" }}
				validate={validate}
				required={required}
			/>
		</div>
	);
};
export default BankAccountOptions;
