import { Component } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import { ContentBlock } from "../components/AppBlocks";
import { Popover } from "antd";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { LegacyInputField, SelectField, SwitchField } from "../components/Fields";
import FormBar from "../components/FormBar";
import LoadErrorView from "../components/LoadErrorView";

import TaxExemptFormInput from "../components/TaxExemptFormInput";
import withSaveCheck from "../hoc/withSaveCheck";
import { getUser, saveUser } from "../state/actions/userActions";
import { scrollToErrors } from "../utils/ScrollToErrors";
import { required } from "../utils/Validators";

const formName = "editUser";

class User extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
	}

	componentWillMount() {
		const { userId } = this.props;
		this.load(userId);
	}

	load(id) {
		const { getUser } = this.props;
		getUser(id);
	}

	save(values) {
		const { userId, saveUser, currentUser } = this.props;
		const { user } = currentUser;
		saveUser(userId, values, false, user.location);
	}

	render() {
		const { currentUser, userId } = this.props;
		const { user } = currentUser;

		if (!user && userId) {
			return <LoadErrorView loading error={currentUser.error} retryAction={this.load} />;
		}
		return (
			<LoadErrorView loading={currentUser.loading} error={currentUser.error} retryAction={this.save}>
				<UserForm save={this.save} userId={userId} currentUser={currentUser} />
			</LoadErrorView>
		);
	}
}

let UserForm = ({ save, handleSubmit, dispatch, currentUser }) => {
	const firstNameRequired = required("A first name is required");
	const lastNameRequired = required("A last name is required");
	const mobileRequired = required("A mobile is required");
	const autoTurnOffAutoPayouts = (_, needsToBeTurnedOff) => {
		if (needsToBeTurnedOff) {
			dispatch(change(formName, "autoPayoutEnabled", false));
		}
	};
	const { user } = currentUser;
	return (
		<form onSubmit={handleSubmit(save)}>
			<ContentBlock>
				<Field name="firstName" label="First name" component={LegacyInputField} validate={firstNameRequired} />
				<Field name="lastName" label="Last Name" component={LegacyInputField} validate={lastNameRequired} />
				<Field name="mobile" label="Mobile" component={LegacyInputField} validate={mobileRequired} />
				<Field name="organization" label="Organisation" component={LegacyInputField} />
				<Field name="email" label="Email" disabled component={LegacyInputField} />
				<Field name="hubspotContactId" label="Hubspot id" component={LegacyInputField} />
				<Field
					label="User Category"
					name="category"
					component={SelectField}
					button
					options={[
						{ label: "Charity", value: "charity" },
						{ label: "Conference", value: "conference" },
						{ label: "Festival", value: "festival" },
						{ label: "Education", value: "education" },
						{ label: "Business", value: "business" }
					]}
				/>
				<Field name="verified" label="Verified" component={SwitchField} />
				<Field name="emailVerified" label="Email verified" component={SwitchField} />
				<Field
					name="fraudulentUser"
					label="Mark user as fraud suspect"
					component={SwitchField}
					onChange={autoTurnOffAutoPayouts}
				/>
				<Field name="autoPayoutEnabled" label="Auto payout enabled" component={SwitchField} />

				<Field name="isReferralProgramEnabled" label="Ambassador program enabled" component={SwitchField} />
				<Field name="isEventImportEnabled" label="Event import enabled" component={SwitchField} />
				<Field name="isTagsEnabled" label="Tags enabled" component={SwitchField} />

				<TaxExemptFormInput formName={formName} />

				<Field name="pinGatewayEnabled" label="Enable PIN Payments Gateway" component={SwitchField} />
				<Field name="disallowAdvertising" label="Disable ThanksAd" component={SwitchField} />
				<Field name="bandsintownVerified" label="Bandsintown verified" component={SwitchField} />
				<Field name="showInsuranceColumn" label="Show insurance column in orders reports" component={SwitchField} />
				<Field
					name="isTiktokPixelIntegrationEnabled"
					label="Show TikTok Pixel as an option under tracking ID integrations"
					component={SwitchField}
				/>

				{user?.otp?.code ? (
					<>
						<Popover title={user.otp.code}>
							<LegacyButton type="primary">View OTP</LegacyButton>
						</Popover>
					</>
				) : null}
			</ContentBlock>

			<FormBar>
				<LegacyButton
					style={{
						float: "right"
					}}
					type="primary"
					htmlType="submit"
					ariaLabel="Save"
					size="large"
				>
					Save
				</LegacyButton>
			</FormBar>
		</form>
	);
};

// Decorate with redux-form
UserForm = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(UserForm);

UserForm = connect((state) => {
	return {
		currentUser: state.currentUser,
		initialValues: state.currentUser.user
	};
})(UserForm);

export default connect(
	(state) => ({
		currentUser: state.currentUser
	}),
	{ getUser, saveUser }
)(withSaveCheck(User, formName));
