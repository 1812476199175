import { ContentBlock } from "@/components/AppBlocks";
import EventSettingsFields from "@/components/EventSettingsFields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { H1 } from "@/components/text/Text";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { usePromptRedux } from "@/hooks/usePrompt";
import { updateEventSettings } from "@/state/actions/eventActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { EventDetails, EventSettings } from "@/state/reducers/eventReducer";
import { ReduxState } from "@/state/store";
import { Button } from "@/ui";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";

const formName = "eventSettings";
const selector = formValueSelector(formName);

const SettingsComponent = ({ handleSubmit }: InjectedFormProps) => {
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const sendTickets = useAppSelector((state) => selector(state, "settings.sendTickets"));
	const afterCheckoutRedirectEnabled = useAppSelector((state) =>
		selector(state, "settings.afterCheckoutRedirect.enabled")
	);
	const uniqueAdditionalQuestionAnswerFields = useAppSelector((state) =>
		selector(state, "settings.uniqueAdditionalQuestionAnswerFields")
	);
	const enableCustomQrData = useAppSelector((state) => selector(state, `settings.associateCustomQrData.enabled`));
	const disableAdditionalParameters = useAppSelector((state) =>
		selector(state, "settings.afterCheckoutRedirect.disableAdditionalParameters")
	);
	const { isAdmin } = useCurrentUser();

	const { eventId } = useParams();
	const dispatch = useAppDispatch();

	usePromptRedux("You have unsaved changes. Are you sure you want to leave?", formName);

	const save = (values: EventDetails) => {
		const newEventSettings: Partial<EventSettings> = {
			...values.settings,
			customTimeout: values?.settings?.customTimeout !== 0 ? values?.settings?.customTimeout : null,
			maximumTicketsPerOrder:
				values?.settings?.maximumTicketsPerOrder !== 0 ? values?.settings?.maximumTicketsPerOrder : null
		};
		const saveObject: any = {
			settings: newEventSettings
		};
		if (values?.settings?.datePickerLayout !== "auto") {
			saveObject.datesUseCalendar = values?.settings?.datePickerLayout === "calendar";
		}
		if (values.ticketTypes && values.ticketTypes.length) {
			saveObject.ticketTypes = values.ticketTypes;
		}
		saveObject.ticketAssigningSettings = values.ticketAssigningSettings;
		dispatch(updateEventSettings(eventId, saveObject));
	};

	return (
		<LoadErrorView
			loading={currentEvent.save.loading}
			error={currentEvent.save.error ? { title: currentEvent.save.error.toString(), message: "oops" } : undefined}
			retryAction={handleSubmit(save)}
		>
			<form onSubmit={handleSubmit(save)}>
				<H1>Settings</H1>
				<ContentBlock>
					<EventSettingsFields
						isAdmin={!!isAdmin}
						currentEvent={currentEvent}
						showSendTicketPerTicket
						sendTickets={sendTickets}
						afterCheckoutRedirectEnabled={afterCheckoutRedirectEnabled}
						uniqueAdditionalQuestionAnswerFields={uniqueAdditionalQuestionAnswerFields}
						enableCustomQrData={enableCustomQrData}
						disableAdditionalParameters={disableAdditionalParameters}
						formName={formName}
					/>
				</ContentBlock>

				<FormBar>
					<Button variant="primary" htmlType="submit" aria-label="Save">
						Save
					</Button>
				</FormBar>
			</form>
		</LoadErrorView>
	);
};

const ReduxFormComponent = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(SettingsComponent);

export const Settings = connect((state: ReduxState) => {
	const event: EventDetails | null = state.currentEvent.event;
	const settingsAllowUserEdit = !!event?.settings?.allowUserEdit;
	const ticketTypes = event?.ticketTypes?.map((ticketType) => {
		if (ticketType.sendTicket !== false) {
			ticketType.sendTicket = true;
		}
		return ticketType;
	});

	return {
		initialValues: {
			...event,
			ticketTypes,
			settings: {
				...event?.settings,
				allowUserEdit: settingsAllowUserEdit,
				ticketAssigningSettings: event?.ticketAssigningSettings || {},
				datePickerLayout: event?.settings?.datePickerLayout || "auto"
			}
		}
	};
})(ReduxFormComponent);
