import Breadcrumb from "@/components/Breadcrumb";
import LoadErrorView from "@/components/LoadErrorView";
import { getUser } from "@/state/actions/userActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { FC, useEffect } from "react";
import SalesforceSetUp from "./SalesforceSetUp";
import SalesforceSettings from "./SalesforceSettings";
import TestSalesforceConnection from "./TestSalesforceConnection";

export const Salesforce: FC<{ userId: string }> = ({ userId }) => {
	const dispatch = useAppDispatch();
	const currentUser = useAppSelector((state) => state.currentUser);

	const userHasSalesforce = currentUser?.user?.salesforceSettings?.refreshToken;
	const showTest = userHasSalesforce && !currentUser.user.salesforceSettings.connectionTested;
	const showSetup = !userHasSalesforce && !currentUser.loading;

	const loadUser = () => {
		dispatch(getUser(userId));
	};

	useEffect(() => {
		loadUser();
	}, []);

	return (
		<LoadErrorView
			loading={!currentUser.user && currentUser.loading}
			error={currentUser.error ? { title: "Oops", message: currentUser.error.toString() } : undefined}
			retryAction={loadUser}
		>
			<Breadcrumb prevPath="/console/account/advanced/integrations" prevTitle="Integrations" currTitle="Salesforce" />
			{showTest && <TestSalesforceConnection userId={userId} />}
			{userHasSalesforce && <SalesforceSettings userId={userId} />}
			{showSetup && <SalesforceSetUp userId={userId} />}
		</LoadErrorView>
	);
};
