import { createTRPCReact } from "@trpc/react-query";
import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import type { AppRouter } from "./trpcRouter";
import TokenService from "@/services/TokenService";
import store from "@/state/store";

export const trpc = createTRPCReact<AppRouter>();

type LocationHeaders = {
	"x-user-level-location"?: string;
	"x-override-location"?: string;
	"x-event-level-location"?: string;
};

const getLocationHeaders = (): LocationHeaders => {
	const { locations } = store.getState();

	let base: LocationHeaders = {
		"x-user-level-location": locations.userLevelLocation || undefined
	};

	if (locations.overrideLocation) {
		base = {
			...base,
			"x-override-location": locations.overrideLocation
		};
	}

	if (locations.eventLevelLocation) {
		base = {
			...base,
			"x-event-level-location": locations.eventLevelLocation
		};
	}

	return base;
};

const trpcClientArgs = {
	links: [
		httpBatchLink({
			url: "/trpc",
			headers() {
				const locationHeaders = getLocationHeaders();

				return {
					"x-token": TokenService.getToken() || "",
					"x-identity": localStorage.getItem("x-identity") || "",
					...locationHeaders
				};
			}
		})
	]
};

export const trpcReactClient = trpc.createClient(trpcClientArgs);
export const trpcProxyClient = createTRPCProxyClient<AppRouter>(trpcClientArgs);
