import { FC } from "react";
import { Flex as AntdFlex, FlexProps as AntdFlexProps } from "antd";
import { spacingTokens, SpacingPreset } from "@/ui";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

type FlexProps = AntdFlexProps & {
	/** The gap between children */
	gap?: SpacingPreset | (string & {}); // Hack to show presets in intellisense while still allowing custom values
	/** Padding for the flex container, accepts a Spacing preset */
	padding?: SpacingPreset | (string & {});
	/** Should the flex container take up the full width of its parent */
	wide?: boolean;
};

const StyledFlex = styled(AntdFlex, {
	shouldForwardProp: (prop) => !["padding", "wide"].includes(prop)
})<FlexProps>`
	${({ padding, wide }) => css`
		${padding && `padding: ${padding};`}
		${wide && "width: 100%;"}
	`}
`;

export const Flex: FC<FlexProps> = ({ gap, padding, wide, ...props }) => {
	if (spacingTokens[gap as SpacingPreset]) {
		const token = spacingTokens[gap as SpacingPreset];
		gap = `var(${token})`;
	}

	if (spacingTokens[padding as SpacingPreset]) {
		const token = spacingTokens[padding as SpacingPreset];
		padding = `var(${token})`;
	}

	return <StyledFlex gap={gap} padding={padding} wide={wide} {...props} />;
};
