import { FullPageWrap, LoadingSpinner } from "@/components/AppBlocks";
import { H2 } from "@/components/text/Text";
import { authenticate } from "@/state/actions/salesforceActions";
import { useAppDispatch } from "@/state/hooks";
import { getUrlParameter } from "@/utils/Urls";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";

export const ConnectSalesforce = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const code = getUrlParameter("code");
	const state = getUrlParameter("state")?.split(":");
	const hasAuthenticated = useRef(false);

	useEffect(() => {
		if (hasAuthenticated.current) return;

		const userId = state?.[0];
		const isSandboxAccount = state?.[1];

		if (code) {
			hasAuthenticated.current = true;
			dispatch(authenticate(userId, code, isSandboxAccount, navigate));
		}
	}, [code, state, dispatch, navigate]);

	return (
		<FullPageWrap>
			<H2 style={{ textAlign: "center" }}>Connecting Salesforce</H2>
			<LoadingSpinner style={{ margin: "60px auto", display: "block" }} />
		</FullPageWrap>
	);
};
