import { CollectionURLParams } from "@/features/collections/editor/CollectionEditorModal";
import { ShareCollectionModal } from "@/features/collections/editor/ShareCollectionModal";
import { useAppSelector } from "@/state/hooks";
import {
	Button,
	CloseIcon,
	Dropdown,
	DropdownItem,
	EditIcon,
	MegaphoneIcon,
	Responsive,
	SaveIcon,
	ShareIcon
} from "@/ui";
import { Flex } from "@/ui/antd";
import { CurrencyLabel } from "@/ui/CurrencyLabel";
import styled from "@emotion/styled";
import { Location } from "@hx/locations";
import { DotsThreeVertical } from "@phosphor-icons/react";
import { FC, useState } from "react";
import { useParams } from "react-router";

const StyledHeader = styled.header`
	align-items: center;
	display: flex;
	gap: var(--spacing-sm);
	justify-content: space-between;
	width: 100%;
`;

const TruncatableText = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

type CollectionEditorModalHeaderProps = {
	activeCollectionName: string | null;
	collectionURL: string;
	isPublishDisabled?: boolean;
	isPublished?: boolean;
	isSaveDisabled?: boolean;
	isSaving?: boolean;
	onClose: () => void;
	onPublish: () => void;
	onRenameCollection: () => void;
	onSave: () => void;
};

export const CollectionEditorModalHeader: FC<CollectionEditorModalHeaderProps> = ({
	activeCollectionName,
	collectionURL,
	isPublishDisabled,
	isPublished,
	isSaveDisabled,
	isSaving,
	onClose,
	onPublish,
	onRenameCollection,
	onSave
}) => {
	const [isShareCollectionModalOpen, setIsShareCollectionModalOpen] = useState(false);
	const params = useParams<CollectionURLParams>();
	const user = useAppSelector((state) => state.auth.user);

	const accessibleLocationsCount = Object.values(user.accessibleLocations).filter(Boolean).length;
	const showCurrencyLabel = accessibleLocationsCount > 1;

	return (
		<StyledHeader>
			<Button iconRight={<EditIcon small />} onClick={onRenameCollection} variant="text">
				<TruncatableText>{activeCollectionName || "Name your collection"}</TruncatableText>
			</Button>
			<Responsive
				desktop={
					<Flex gap="lg">
						{showCurrencyLabel && <CurrencyLabel location={params.location as Location} noLabel />}
						<Button onClick={onClose} variant="ghost">
							Close
						</Button>
						{isPublished && (
							<Button
								iconLeft={<ShareIcon small />}
								onClick={() => setIsShareCollectionModalOpen(true)}
								variant="tertiary"
							>
								Share
							</Button>
						)}
						<Button disabled={isSaveDisabled} isLoading={isSaving} onClick={onSave} variant="secondary">
							Save
						</Button>
						<Button disabled={isPublishDisabled} onClick={onPublish} variant="primary">
							{isPublished ? "Unpublish" : "Publish"}
						</Button>
					</Flex>
				}
				mobile={
					<Flex gap="lg">
						{showCurrencyLabel && <CurrencyLabel location={params.location as Location} noCurrency noLabel />}
						<Dropdown
							items={
								<>
									{isPublished ? (
										<DropdownItem
											iconLeft={<ShareIcon />}
											onClick={() => setIsShareCollectionModalOpen(true)}
											variant="text"
										>
											Share
										</DropdownItem>
									) : null}
									<DropdownItem
										disabled={isSaveDisabled}
										iconLeft={<SaveIcon small />}
										isLoading={isSaving}
										onClick={onSave}
										variant="text"
									>
										Save
									</DropdownItem>
									<DropdownItem
										iconLeft={<MegaphoneIcon small />}
										onClick={onPublish}
										variant={isPublished ? "danger-text" : "primary"}
									>
										{isPublished ? "Unpublish" : "Publish"}
									</DropdownItem>
								</>
							}
						>
							<Button aria-label="More options" iconOnly variant="text">
								<DotsThreeVertical size={18} weight="bold" />
							</Button>
						</Dropdown>
						<Button iconOnly variant="text" onClick={onClose}>
							<CloseIcon small />
						</Button>
					</Flex>
				}
			/>
			<ShareCollectionModal
				collectionURL={collectionURL}
				open={isShareCollectionModalOpen}
				onCloseClicked={() => setIsShareCollectionModalOpen(false)}
			/>
		</StyledHeader>
	);
};
