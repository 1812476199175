import { SocialLinkModal } from "@/features/collections/configPanel/infoTab/SocialLinkModal";
import { Button, DeleteIcon, EditIcon, PlusIcon } from "@/ui";
import { FormInstance, Row, Space, Typography } from "@/ui/antd";
import styled from "@emotion/styled";
import { Collection } from "@hx/console";
import { FC, useState } from "react";

const StyledButton = styled(Button)`
	min-height: 30px;
	min-width: 30px;
	padding: 8px;
`;

const StyledAddLinkButton = styled(Button)`
	align-items: center;
	justify-content: flex-start;
`;

type SocialLinkRowItemProps = {
	form: FormInstance<Collection>;
	index: number;
	onDelete: () => void;
};

export const SocialLinkRowItem: FC<SocialLinkRowItemProps> = ({ form, index, onDelete }) => {
	const [editLinkModalOpen, setEditLinkModalOpen] = useState(false);

	const socialLinkItem = form.getFieldValue(["info", "socialLinks", index]);
	const { name, url, type } = socialLinkItem || {};

	const hasUrl = url?.length > 0;

	const handleOpenModal = () => setEditLinkModalOpen(true);

	const handleCloseModal = () => setEditLinkModalOpen(false);

	const handleFinishModal = (values: Collection["info"]["socialLinks"][0]) => {
		const existingInfo = form.getFieldValue("info") as Collection["info"];
		form.setFieldsValue({
			info: {
				socialLinks: existingInfo.socialLinks.map((item, i) => (i === index ? values : item))
			}
		});
		handleCloseModal();
	};

	return (
		<Row style={{ marginBottom: "12px", width: "100%" }}>
			{hasUrl ? (
				<Row align="middle" style={{ flexGrow: 1, paddingLeft: "16px" }}>
					<Typography.Text style={{ fontWeight: 500 }}>{name}</Typography.Text>
				</Row>
			) : (
				<StyledAddLinkButton fullWidth iconLeft={<PlusIcon small />} onClick={handleOpenModal} variant="text">
					{name}
				</StyledAddLinkButton>
			)}

			{url && (
				<Space size={8}>
					<StyledButton iconOnly variant="text" onClick={handleOpenModal}>
						<EditIcon small />
					</StyledButton>
					<StyledButton iconOnly onClick={onDelete} variant="text">
						<DeleteIcon small />
					</StyledButton>
				</Space>
			)}

			<SocialLinkModal
				initialValues={{ name, url, type }}
				onCancel={handleCloseModal}
				onSubmit={handleFinishModal}
				open={editLinkModalOpen}
			/>
		</Row>
	);
};
