export const getConfig = (key: string, locale?: string) => {
	const fullKey = `${key}${locale ? `_${locale}` : ""}`;
	let value = (window as any)?.config?.[fullKey];
	if (value === undefined) value = (window as any)?.config?.[key];
	if (value === undefined) {
		console.trace("Missing env var", key);
		throw new Error(`Environment variable not set key:${key} locale:${locale}`);
	}
	value = value === "true" ? true : value;
	value = value === "false" ? false : value;

	return value;
};

export const gatewayRequiresPayout = (gateway: string): boolean => {
	return gateway !== "stripe" && gateway !== "pin";
};

export const getFeatureFlags = (isAdmin: boolean) => {
	const nodeEnv = getConfig("NODE_ENV");
	const isNotProd = nodeEnv !== "production";
	return {
		// Mass refunds should available to all users in staging and dev but only to admins in production
		massRefunds: isAdmin || isNotProd,
		// During the internal testing phase Google SSO, we want a separate flag for it just in case admins have already set it up but we need to pause login via SSO.
		allowGoogleSSO: isAdmin,
		// While we build out SSO, we want to only display the security settings to admins in dev/staging, and hide it for all users in production.
		securitySettings: isAdmin
	};
};
