import { ContentBlock } from "@/components/AppBlocks";
import PageHeading from "@/components/PageHeading";
import { SignInMethods } from "./SignInMethods";

export const Security = () => {
	return (
		<>
			<PageHeading title="Security" />
			<ContentBlock>
				<SignInMethods />
			</ContentBlock>
		</>
	);
};
