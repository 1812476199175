import UsersService from "@/services/UsersService";
import { toggleApplyToAllModal } from "@/state/actions/applyToAllActions";
import { setUser } from "@/state/actions/authActions";
import { useAppSelector } from "@/state/hooks";
import { setLoading } from "@/state/slices/auth";
import { trpc } from "@/trpc";
import { CurrentUserSchema } from "@hx/console";
import { notification } from "antd";
import { useDispatch } from "react-redux";

export const useCurrentUser = () => {
	const dispatch = useDispatch();
	const user = useAppSelector((state) => state.auth.user);
	const token = useAppSelector((state) => state.auth.token);
	const { refetch: fetchUser, isLoading } = trpc.users.getCurrentUser.useQuery(undefined, { enabled: false });

	const { isAdmin, hasEventImportAccess, hasTagAccess, signInMethods } = user ?? {};

	const queryUserData = async (): Promise<CurrentUserSchema | void> => {
		const { data, error } = await fetchUser();
		const userData = data?.user;

		if (error) {
			notification.error({ message: "Error", description: error?.message });
			return;
		}

		return userData;
	};

	const refreshUserStateData = async (): Promise<CurrentUserSchema | void> => {
		dispatch(setLoading(true));
		const data = await queryUserData();
		if (data) {
			dispatch(setUser(data));
		}
		dispatch(setLoading(false));
		return data;
	};

	type UpdateUserPayload = Partial<CurrentUserSchema> & {
		applyToAllRequired?: boolean;
	};

	const updateUser = async (userData: UpdateUserPayload, showNotification = true) => {
		try {
			await UsersService.userUpdate(userData);
			await refreshUserStateData();
			if (userData.applyToAllRequired) {
				dispatch(toggleApplyToAllModal({ stateToApplyToModal: true }));
			} else if (showNotification) {
				notification.success({
					message: "Updated",
					description: "Your details have been updated"
				});
			}
		} catch (err) {
			notification.error({ message: "Error", description: (err as Error).message });
		}
	};

	const hasAdminPermission = (permissionToCheck: "editPermissions" | "payouts" | "taxInfo" | "selfServicePayouts") => {
		if (!isAdmin) {
			return false;
		}

		return !!user?.adminPermissions?.includes(permissionToCheck);
	};

	const hasSignInMethod = (method: "password" | "google.com") => signInMethods?.includes(method);

	return {
		hasAdminPermission,
		hasEventImportAccess,
		hasSignInMethod,
		hasTagAccess,
		isAdmin,
		isLoading,
		refreshUserStateData,
		signInMethods,
		token,
		updateUser,
		user
	};
};
