import { LegacyButton } from "@/components/buttons/LegacyButton";
import Label from "@/components/text/Label";
import P from "@/components/text/P";
import {
	toggleAutoPayoutForEvent,
	toggleFlaggedForEvent,
	toggleHideNilPayouts
} from "@/state/actions/newPayoutActions";
import { PayoutHeaderInformation, BankAccount } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { Alert, Switch, Tooltip } from "@/ui/antd";
import { css } from "@emotion/css";
import { BankAccounts } from "@hx/utilities";
import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FraudWarnings from "../components/FraudWarnings";

//icons
import icAutoPayout from "@/assets/payouts/ic_auto_payout.svg";
import icFraudFail from "@/assets/payouts/ic_fraud_fail.svg";
import icFraudPass from "@/assets/payouts/ic_fraud_pass.svg";
import icFraudSuspect from "@/assets/payouts/ic_fraud_suspect.svg";
import icUserFail from "@/assets/payouts/ic_user_fail.svg";
import icUserPass from "@/assets/payouts/ic_user_pass.svg";
import icFlagged from "@/assets/payoutsSearch/flagged.svg";
import icCross from "@/assets/payoutsSearch/ic_cross.svg";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { HumaniDate } from "@hx/dates";
import TrackRecord from "../components/TrackRecord";

const wrapper = css`
	display: flex;
	justify-content: space-between;
	div {
		display: flex;
		flex-direction: column;
	}
`;

const QuickBit = ({
	isTrue,
	trueToolTipText,
	falseToolTipText,
	trueText,
	falseText,
	trueIcon,
	falseIcon,
	hideOnFalse = false
}: {
	isTrue: boolean;
	trueToolTipText: string;
	falseToolTipText?: string;
	trueText: string;
	falseText?: string;
	trueIcon: string;
	falseIcon?: string;
	hideOnFalse?: boolean;
}) => {
	if (hideOnFalse && !isTrue) return null;
	return (
		<span style={{ display: "flex", alignItems: "flex-start" }}>
			<Tooltip title={isTrue ? trueToolTipText : falseToolTipText}>
				<img src={isTrue ? trueIcon : falseIcon} style={{ marginRight: 8, width: 20, height: 20 }} />
			</Tooltip>
			<P>{isTrue ? trueText : falseText}</P>
		</span>
	);
};

const Header = ({
	header,
	toggleAutoPayoutForEvent,
	filters,
	toggleHideNilPayouts,
	toggleFlaggedForEvent
}: IInjectedFunctions & IInjectedProps) => {
	const { isAdmin } = useCurrentUser();

	const [hideNilPayouts, setHideNilPayouts] = useState(true);

	if (!header) return null;

	const getBankAccountParts = (bankAccount: BankAccount) => {
		let accountName = bankAccount.name;
		if (String(bankAccount.location) === "US") {
			if (bankAccount.accountHolderType === "individual") {
				accountName = [bankAccount.firstName, bankAccount.lastName].join(" ");
			} else if (bankAccount.accountHolderType === "business") {
				accountName = bankAccount.companyName;
			} else {
				accountName = bankAccount.bankName;
			}
		}

		return BankAccounts.getDisplayParts(
			String(bankAccount.bsb),
			bankAccount.number,
			String(bankAccount.location),
			accountName
		);
	};

	return (
		<>
			<Alert
				type="info"
				showIcon
				message={
					<>
						Payouts are completed within five business days of the event concluding. Need faster payouts? Integrate{" "}
						<Link to="/console/account/profile/default-gateways">Stripe</Link>
					</>
				}
				style={{ marginBottom: 24 }}
			/>
			<div className={wrapper}>
				<div>
					<b>{header.name}</b>
					<b>{header.email}</b>
					<b>{header.mobile}</b>
				</div>

				{header.bankAccount ? (
					<div>
						{getBankAccountParts(header.bankAccount).map((part, index) => (
							<P key={index}>{part}</P>
						))}
						{isAdmin ? (
							<>
								<hr />
								<P>B/A updated at: {new HumaniDate(header.bankAccount.updatedAt, "guess").formatting.dateTime()}</P>
								{header.userBankAccountIdUpdatedAt ? (
									<P>
										B/A applied at: {new HumaniDate(header.userBankAccountIdUpdatedAt, "guess").formatting.dateTime()}
									</P>
								) : null}
							</>
						) : null}
					</div>
				) : null}

				{isAdmin ? (
					<div>
						<QuickBit
							isTrue={header.isUserVerified}
							trueToolTipText="User is verified"
							falseToolTipText="User is not verified"
							trueText="User verified"
							falseText="User not verified"
							trueIcon={icUserPass}
							falseIcon={icUserFail}
						/>

						<QuickBit
							isTrue={header.isEventTriggeringFraud}
							trueToolTipText="Event is triggering fraud warnings"
							falseToolTipText="Event is not triggering fraud warnings"
							trueText="Fraud check fail"
							falseText="Fraud check pass"
							trueIcon={icFraudFail}
							falseIcon={icFraudPass}
						/>

						<QuickBit
							isTrue={!header.isBankAccountValid}
							trueToolTipText="Bank account is not valid"
							trueText="Bank account not valid"
							trueIcon={icFraudFail}
							hideOnFalse
						/>

						<QuickBit
							isTrue={header.isEventFlagged}
							trueToolTipText="Event has been flagged"
							trueText="Event flagged"
							trueIcon={icFlagged}
							hideOnFalse
						/>

						<QuickBit
							isTrue={header.isUserMarkedAsFraud}
							trueToolTipText="User marked as fraudulent by admin"
							trueText="Fraudulent user"
							trueIcon={icFraudSuspect}
							hideOnFalse
						/>

						<QuickBit
							isTrue={header.isAutoPayoutEnabled}
							trueToolTipText="Auto payout enabled"
							trueText="Auto payout enabled"
							trueIcon={icAutoPayout}
							hideOnFalse
						/>

						<QuickBit
							isTrue={header.isUserMissingTaxInfo}
							trueToolTipText="User requires tax information to proceed"
							trueText="Tax information"
							trueIcon={icCross}
							hideOnFalse
						/>
					</div>
				) : null}
			</div>

			{isAdmin ? <FraudWarnings /> : null}
			{isAdmin ? <TrackRecord /> : null}
			<hr />

			{isAdmin ? (
				<>
					<div className={wrapper}>
						<LegacyButton onClick={() => toggleAutoPayoutForEvent(header.eventId)}>
							{header.isAutoPayoutEnabled ? "Disable auto payout" : "Enable auto payout"}
						</LegacyButton>

						<LegacyButton onClick={() => toggleFlaggedForEvent(header.eventId)}>
							{header.isEventFlagged ? "Unflag event" : "Flag event"}
						</LegacyButton>

						<span>
							<Label>Hide nil payments</Label>
							<Switch
								checked={filters.hideNilPayouts}
								onClick={() => {
									toggleHideNilPayouts();
									setHideNilPayouts(!hideNilPayouts);
								}}
							/>
						</span>
					</div>
					<hr />
				</>
			) : null}
		</>
	);
};

interface IInjectedProps {
	header?: PayoutHeaderInformation;
	filters: ReduxState["payouts"]["filters"];
}

interface IInjectedFunctions {
	toggleAutoPayoutForEvent: (eventId: string) => void;
	toggleHideNilPayouts: () => void;
	toggleFlaggedForEvent: (eventId: string) => void;
}

export default connect(
	(state: ReduxState): IInjectedProps => ({
		header: state.payouts.eventPayout.payoutInformation?.header,
		filters: state.payouts.filters
	}),
	{ toggleAutoPayoutForEvent, toggleHideNilPayouts, toggleFlaggedForEvent }
)(Header);
