import { Button } from "@/ui";
import { Flex } from "@/ui/antd";
import { QrcodeOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import QRCodeStyling, { Options } from "qr-code-styling";
import { FC, ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import Logo from "../assets/logos/logo-icon-peach.svg";

const container = (width: number) => css`
	display: flex;
	justify-content: center;
	width: ${width}px;
	transition: width 0.1s ease-in-out;
	margin: auto;

	canvas {
		width: 100%;
	}
`;

export const qRCodeStyle: Partial<Options> = {
	width: 1000,
	height: 1000,
	type: "canvas",
	data: "https://www.humanitix.com/",
	image: Logo,
	dotsOptions: {
		color: "#353337",
		type: "rounded"
	},
	backgroundOptions: {
		color: "#ffffff"
	},
	imageOptions: {
		crossOrigin: "anonymous",
		margin: 8
	}
};

const qrCode = new QRCodeStyling(qRCodeStyle);
const qrCodeDownloadable = new QRCodeStyling(qRCodeStyle);

type QRCodeProps = {
	scanUrl: string;
	showLogo?: boolean;
	enlarge?: boolean;
	allowDownload?: boolean;
};

export const QRCode: FC<QRCodeProps> = ({ scanUrl: inputUrl, showLogo, allowDownload, enlarge }) => {
	const ref = useRef(null);
	const downloadableRef = useRef(null);
	const portalContainer = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!portalContainer.current) {
			portalContainer.current = document.createElement("div");
			portalContainer.current.id = "qr-code-downloadable-portal";
			document.body.appendChild(portalContainer.current);
		}

		if (ref.current) {
			qrCode.append(ref.current);
		}

		if (downloadableRef.current) {
			qrCodeDownloadable.append(downloadableRef.current);
		}

		qrCode.update({
			data: inputUrl,
			image: showLogo ? Logo : null
		});

		qrCodeDownloadable.update({
			data: inputUrl
		});

		return () => _removePortal();
	}, [ref.current, downloadableRef.current, inputUrl]);

	const _handleDownloadClick = async () => {
		await qrCodeDownloadable.download({ name: "humanitix-qr", extension: "png" });
	};

	const _removePortal = () => {
		if (portalContainer.current) {
			document.body.removeChild(portalContainer.current);
			portalContainer.current = null;
		}
	};

	const renderedPortal = portalContainer.current
		? (createPortal(
				<div
					ref={downloadableRef}
					style={{
						position: "absolute",
						top: -99999
					}}
				/>,
				portalContainer.current
		  ) as ReactNode)
		: null;

	return (
		<div data-qrtooltip>
			<div aria-label="QR code" className={container(enlarge ? 350 : 150)} ref={ref} />
			{allowDownload ? (
				<Flex justify="center" style={{ marginTop: 16 }}>
					<Button
						onClick={_handleDownloadClick}
						variant="tertiary"
						iconLeft={<QrcodeOutlined style={{ marginRight: 8 }} />}
					>
						Download QR
					</Button>
				</Flex>
			) : null}
			{renderedPortal}
		</div>
	);
};
