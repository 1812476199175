import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { loadSDK } from "@/state/actions/facebookActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { HelpIds } from "@/utils/Help";
import { useEffect } from "react";
import { ConnectFacebook } from "./ConnectFacebook";
import PostToFacebook from "./PostToFacebook";

export const FacebookEvent = () => {
	const dispatch = useAppDispatch();
	const facebook = useAppSelector((state) => state.facebook);
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const facebookSettings = useAppSelector((state) => state.auth.user?.facebookSettings);

	useEffect(() => {
		if (!facebook.sdk.loaded && !facebook.sdk.loading) {
			dispatch(loadSDK());
		}
	}, []);

	const facebookConnected = !!facebookSettings?.connection?.userId;
	let pageAccess = true;

	if (facebook?.account?.loaded) {
		const facebookEvent = currentEvent?.event?.facebookEvents?.length ? currentEvent.event.facebookEvents[0] : false;
		const page = facebookEvent?.pageId
			? facebook?.account?.pages?.find((page: { id: string }) => page.id === facebookEvent.pageId)
			: null;

		if (facebookEvent?.pageId && facebookEvent.facebookId && !page) {
			// the user is connected but does not have pageAccess
			pageAccess = false;
		}
	}

	return (
		<LoadErrorView loading={facebook.sdk.loading || facebook.connectionCheck.loading}>
			<PageHeading
				title="Facebook Event"
				helpButton={{
					link: HelpIds.facebookEvent,
					title: "Help - Facebook Event"
				}}
			/>
			{!facebookConnected || !pageAccess ? <ConnectFacebook pageAccess={pageAccess} /> : null}
			{facebookConnected && pageAccess ? <PostToFacebook /> : null}
		</LoadErrorView>
	);
};
