import { FullPageWrap, LoadingSpinner } from "@/components/AppBlocks";
import { H2 } from "@/components/text/Text";
import MailchimpService from "@/services/MailchimpService";
import { useAppSelector } from "@/state/hooks";
import { notification } from "@/ui/antd";
import { getUrlParameter } from "@/utils/Urls";
import { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router";

export const ConnectMailchimp: FC = () => {
	const navigate = useNavigate();
	const userId = useAppSelector((state) => state.auth.user?._id);
	const code = getUrlParameter("code");
	const hasAuthenticated = useRef(false);

	useEffect(() => {
		if (hasAuthenticated.current) return;

		if (code && userId) {
			hasAuthenticated.current = true;
			MailchimpService.getToken(code, userId)
				.then(() => {
					notification.success({
						message: "Authenticated",
						description: "Your account is connected with Mailchimp"
					});
				})
				.catch((err) => {
					const message =
						err && err.response && err.response.data && err.response.data.error
							? err.response.data.error
							: "Failed to connect with Mailchimp";
					notification.error({
						message: "Authenticate Error",
						description: message
					});
				})
				.finally(() => {
					navigate(`/console/account/advanced/integrations/mailchimp`);
				});
		} else {
			navigate(`/console/account/advanced/integrations/mailchimp`);
		}
	}, [code, userId]);

	return (
		<FullPageWrap>
			<H2 style={{ textAlign: "center" }}>Connecting Mailchimp</H2>
			<LoadingSpinner style={{ margin: "60px auto", display: "block" }} />
		</FullPageWrap>
	);
};
