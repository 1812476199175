import { ForgotPasswordLink } from "@/auth/components/Links";
import H4 from "@/components/text/H4";
import P from "@/components/text/P";
import { parseErrorMessage } from "@/hooks/useAuth";
import { Flex, Form, FormInstance, notification } from "@/ui/antd";
import { Button } from "@/ui/Button";
import { FormItem } from "@/ui/FormItem";
import { Password } from "@/ui/Password";
import { ResponsiveModal } from "@/ui/ResponsiveModal";
import { FirebaseError } from "firebase/app";
import {
	AuthErrorCodes,
	EmailAuthProvider,
	getAuth,
	reauthenticateWithCredential,
	UserCredential
} from "firebase/auth";
import { createContext, FC, ReactNode, useContext, useRef, useState } from "react";

const ReauthenticationContext = createContext<ReauthenticationContextType | undefined>(undefined);

export const errorRequiresMfa = (error: FirebaseError) => error.code === AuthErrorCodes.MFA_REQUIRED;

const PasswordForm: FC<{
	form: FormInstance;
	onFormFinished: () => void;
	isShowing: boolean;
}> = ({ form, onFormFinished, isShowing }) => {
	return (
		<Form
			style={{ width: "100%" }}
			layout="vertical"
			form={form}
			onFinish={onFormFinished}
			autoComplete="off"
			hidden={!isShowing}
		>
			<P>Please re-enter your password to continue.</P>

			<FormItem
				style={{ marginBottom: 12 }}
				changeTriggerOnBlur
				name="password"
				required
				rules={[
					{
						min: 1,
						required: true,
						whitespace: true,
						message: "Your password is required."
					}
				]}
				validateFirst
			>
				<Password autoComplete="off" placeholder="Password" />
			</FormItem>

			<ForgotPasswordLink />
		</Form>
	);
};

export const ReauthenticationProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const [reauthenticateForm] = Form.useForm();

	const resolveRef = useRef<(value: UserCredential | PromiseLike<UserCredential>) => void>();
	const rejectRef = useRef<(value: Error | PromiseLike<Error>) => void>();

	const resetForms = () => {
		reauthenticateForm.resetFields();
	};

	const closeModal = (isSuccessful?: boolean) => {
		setLoading(false);
		resetForms();
		setIsOpen(false);

		if (!isSuccessful) {
			rejectRef.current?.(new Error("Modal closed before reauthentication was successful"));
		}
	};

	const successCallback = (userCredential: UserCredential) => {
		closeModal(true);
		resolveRef.current?.(userCredential);
	};

	const reauthenticateWithPassword = async (password: string): Promise<UserCredential> => {
		const loggedInUser = getAuth().currentUser;
		if (!loggedInUser) {
			throw new Error("User not found");
		}
		if (!loggedInUser.email) {
			throw new Error("User email is missing");
		}

		return await reauthenticateWithCredential(loggedInUser, EmailAuthProvider.credential(loggedInUser.email, password));
	};

	const handleReauthentication = async () => {
		try {
			const formValues = reauthenticateForm.getFieldsValue(true);
			const { password } = formValues;
			setLoading(true);
			const userCredential = await reauthenticateWithPassword(password);
			successCallback(userCredential);
		} catch (error) {
			notification.error({
				message: "Error",
				description: parseErrorMessage(error as { code: string; message: string })
			});
		} finally {
			setLoading(false);
		}
	};

	/**
	 * Promise that resolves when the user has successfully reauthenticated.
	 */
	const reauthenticationPromise = (): Promise<UserCredential> => {
		return new Promise((resolve, reject) => {
			resolveRef.current = resolve;
			rejectRef.current = reject;
		});
	};

	/**
	 * Reauthenticate the user to continue any operation that requires reauthentication. This may also prompt the user to enter a TOTP code if the user has MFA enabled.
	 */
	const reauthenticate = async (): Promise<UserCredential> => {
		setIsOpen(true);
		return reauthenticationPromise();
	};

	const reauthModal = (
		<ResponsiveModal
			open={isOpen}
			header={<H4 style={{ marginBottom: 0 }}>Please verify your account to continue</H4>}
			onCancel={() => closeModal(false)}
			width="620px"
			footer={
				<Flex justify="space-between" wide>
					<Button aria-label="Cancel" variant="tertiary" onClick={() => closeModal(false)}>
						Cancel
					</Button>
					<Button
						aria-label="Continue"
						variant="primary"
						onClick={() => {
							reauthenticateForm.submit();
						}}
						isLoading={loading}
					>
						{loading ? "Please wait..." : "Continue"}
					</Button>
				</Flex>
			}
		>
			<PasswordForm form={reauthenticateForm} onFormFinished={handleReauthentication} isShowing />
		</ResponsiveModal>
	);

	return (
		<ReauthenticationContext.Provider
			value={{
				ReauthenticationModal: reauthModal,
				reauthenticate,
				closeModal,
				loading,
				setLoading
			}}
		>
			{children}
		</ReauthenticationContext.Provider>
	);
};

type ReauthenticationContextType = {
	reauthenticate: () => Promise<UserCredential>;
	closeModal: () => void;
	loading: boolean;
	ReauthenticationModal: ReactNode;
	setLoading: (loading: boolean) => void;
};

/**
 * Hook to use the reauthentication context.
 * This allows us to render the modal once at the top of the App, and then we're able to call reauthenticate/reauthenticateWithTotp from anywhere in the app.
 */
export const useReauthentication = () => {
	const context = useContext(ReauthenticationContext);
	if (!context) {
		throw new Error("useReauthentication must be used within a ReauthenticationProvider");
	}

	const { ReauthenticationModal, reauthenticate } = context;

	return {
		reauthenticate,
		ReauthenticationModal
	};
};
