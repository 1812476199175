import { useEffect, useState } from "react";
import FormBar from "@/components/FormBar";
import ButtonsBar from "@/components/buttons/ButtonsBar";

import { connect } from "react-redux";
import { reduxForm, change, getFormValues } from "redux-form";
import LoadErrorView from "@/components/LoadErrorView";
import { Steps, Button } from "@/ui/antd";
import { ContentBlock } from "@/components/AppBlocks";
import { goToPathWithSearchState } from "@/utils/Urls";

import { saveBankAccounts, getUser } from "../state/actions/userActions";
import { editBankAccount, endEditingBankAccount } from "../state/actions/bankAccountsActions";
import { useParams, useLocation } from "react-router";
import { BankAccountForm } from "./BankAccountForm";
import { USTaxPayerForm } from "./USTaxPayerForm";
import { OTPModal } from "@/components/OTPModal";

let BankAccountAndTaxForm = ({
	authUser,
	saveBankAccounts,
	handleSubmit,
	bankAccounts,
	currentUser,
	formValues,
	userId,
	getUser,
	editBankAccount,
	endEditingBankAccount,
	overrideLocation,
	dispatch
}) => {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const redirectPath = searchParams.get("redirect") ?? "/console/account/profile/bank-accounts";
	const { bankAccountId } = useParams();

	const isNewBankAccount = !bankAccounts.account?._id;
	const isLocationUS = overrideLocation === "US";

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		if (bankAccountId && bankAccountId !== "new" && currentUser.user) {
			const selectedBankAccount = currentUser.user?.bankAccounts.find(
				(account) => String(account._id) === bankAccountId
			);
			editBankAccount(selectedBankAccount);
		}
	}, [currentUser.user]);

	const save = (values) => {
		const canAddBankAccounts = !!(
			!authUser ||
			!currentUser ||
			!currentUser.user ||
			authUser.id === currentUser.user.id
		);

		if (!canAddBankAccounts) {
			return false;
		}
		const bankAccount = { ...values, location: overrideLocation };

		saveBankAccounts({
			userId: currentUser.user.id,
			bankAccount,
			redirectPath
		});
	};

	const handleCancel = () => {
		endEditingBankAccount();
		goToPathWithSearchState(redirectPath);
	};

	const load = () => {
		getUser(userId);
	};

	const clearTaxpayerInfoFields = () => {
		if (formValues?.usTaxpayerInfo) {
			Object.keys(formValues?.usTaxpayerInfo).forEach((key) =>
				key !== "type" ? dispatch(change(formName, `usTaxpayerInfo.${key}`, null)) : false
			);
		}
	};

	const loading = bankAccounts.loading || !currentUser.user;

	const [currStep, setStep] = useState(0);

	function next() {
		setStep(1);
	}

	function prev() {
		setStep(0);
	}

	const steps = [
		...(isLocationUS
			? [
					{
						title: "Tax payer info",
						content: (
							<USTaxPayerForm
								clearTaxpayerInfoFields={clearTaxpayerInfoFields}
								formValues={formValues?.usTaxpayerInfo}
							/>
						)
					}
			  ]
			: []),
		{
			title: "Bank account info",
			content: (
				<BankAccountForm location={overrideLocation} formValues={formValues} isNewBankAccount={isNewBankAccount} />
			)
		}
	];

	return (
		<form onSubmit={handleSubmit(save)}>
			<LoadErrorView loading={loading} retryAction={load}>
				<ContentBlock>
					{isLocationUS ? (
						<Steps
							current={currStep}
							items={steps.map((item) => ({ key: item.title, title: item.title }))}
							size="small"
							style={{ maxWidth: 500, margin: "0 auto 30px" }}
						/>
					) : null}
					{steps[currStep].content}
				</ContentBlock>
			</LoadErrorView>

			<FormBar>
				<ButtonsBar footer>
					<Button
						key="back"
						onClick={isLocationUS && currStep === 1 ? prev : handleCancel}
						htmlType="button"
						ariaLabel="back"
					>
						{isLocationUS && currStep === 1 ? "Back" : "Cancel"}
					</Button>

					{isLocationUS && currStep === 0 ? (
						<Button type="primary" key="continue" onClick={handleSubmit(next)} htmlType="button" ariaLabel="continue">
							Continue
						</Button>
					) : (
						<OTPModal submitFunction={handleSubmit(save)} handleSubmit={handleSubmit} />
					)}
				</ButtonsBar>
			</FormBar>
		</form>
	);
};

const formName = "bankAccount";

BankAccountAndTaxForm = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(BankAccountAndTaxForm);

export default connect(
	(state) => {
		const formValues = getFormValues(formName)(state) || {};

		const initialValues = state.bankAccounts.account || {};

		return {
			initialValues,
			currentUser: state.currentUser,
			authUser: state.auth.user,
			bankAccounts: state.bankAccounts,
			formValues,
			overrideLocation: state.locations.overrideLocation || state.currentUser?.user?.location || "AU"
		};
	},
	{ getUser, saveBankAccounts, editBankAccount, endEditingBankAccount }
)(BankAccountAndTaxForm);
