import icon from "@/assets/menu/ic_user.svg";
import { getFeatureFlags } from "@/config";
import { MenuItem } from "@/services/menuService/MenuService";
import store from "@/state/store";

const evaluateSettingsChildren = (): MenuItem[] => {
	const { auth } = store.getState();
	const { hasEventInTheUS, hasTagAccess, hasReferralProgram, isAdmin } = auth?.user ?? {};

	const { securitySettings } = getFeatureFlags(!!isAdmin);

	const menu = {
		label: "My account",
		key: "account-myaccount",
		subMenu: true,
		path: `/console/account`,
		children: [
			{
				label: "Profile",
				path: "/console/account",
				key: "account-myaccount-account"
			},
			securitySettings
				? {
						label: "Security",
						path: "/console/account/profile/security",
						key: "account-myaccount-security"
				  }
				: null,
			{
				label: "Default settings",
				path: "/console/account/profile/default-setting",
				key: "account-myaccount-default-setting"
			},
			{
				label: "Default payment settings",
				path: "/console/account/profile/default-payment-setting",
				key: "account-myaccount-default-payment-setting"
			},
			{
				label: "Default gateways",
				path: "/console/account/profile/default-gateways",
				key: "account-myaccount-default-gateways"
			},
			{
				label: "Default notifications",
				path: "/console/account/profile/alerts",
				key: "account-myaccount-alerts"
			},
			{
				label: hasEventInTheUS ? "Tax & bank details" : "Bank accounts",
				path: "/console/account/profile/bank-accounts",
				key: "account-myaccount-bank-accounts"
			}
		].filter((item) => !!item)
	};

	const advancedHomeMenu = {
		label: "Advanced",
		key: "account-advanced",
		subMenu: true,
		path: `/console/account/advanced/integrations`,
		children: [
			{
				label: "Integrations",
				path: "/console/account/advanced/integrations",
				key: "account-advanced-integrations"
			},
			{
				label: "Public API key",
				path: `/console/account/advanced/api-key`,
				key: "account-advanced-api-key"
			},
			{
				label: "Global widgets",
				path: `/console/account/advanced/global-widgets`,
				key: "account-advanced-global-widgets"
			},
			{
				label: "Templates",
				path: `/console/account/advanced/templates`,
				key: "account-advanced-templates"
			}
		]
	};

	if (hasTagAccess) {
		advancedHomeMenu.children.push({
			label: "Tags",
			path: "/console/account/advanced/tags",
			key: "account-advanced-tags"
		});
	}

	if (hasReferralProgram) {
		advancedHomeMenu.children.splice(3, 0, {
			label: "Ambassador program",
			path: `/console/account/advanced/referral-program`,
			key: "account-advanced-referral-program"
		});
	}

	const settingsChildren = [
		menu,
		{
			label: "Permissions",
			path: "/console/account/authorization",
			key: "account-authorization"
		},
		{
			label: "Hosts",
			path: "/console/account/hosts",
			key: "account-hosts"
		},
		advancedHomeMenu
	];

	return settingsChildren;
};

export const accountMenu = (): MenuItem[] => {
	return [
		{
			label: "Account",
			subMenu: true,
			key: "account",
			path: "/console/account",
			children: evaluateSettingsChildren(),
			icon
		}
	];
};
