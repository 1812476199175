import { DesktopIcon, LinkOutIcon, MobileIcon } from "@/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, ReactNode, useState } from "react";
import { createPortal } from "react-dom";

export type PreviewMode = "desktop" | "phone";

const PreviewContainer = styled.div`
	align-items: center;
	background-color: #edecec;
	border-radius: 20px;
	border: 1px solid rgba(21, 20, 19, 0.31);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
	width: 100%;
`;

const Header = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	gap: 8px;
	justify-content: space-between;
	padding: 14px 32px;
	position: relative;
	width: 100%;

	@media (max-width: 768px) {
		padding: 14px 16px;
	}
`;

const PreviewActionsContainer = styled.div`
	align-items: center;
	background-color: white;
	border-radius: 4px;
	display: flex;
	gap: 16px;
	height: 36px;
	justify-content: center;
	overflow: hidden;
	padding: 6px 8px;
`;

const ToggleButton = styled.button<{
	isActive?: boolean;
}>`
	align-items: center;
	background-color: ${({ isActive }) => (isActive ? "#eee" : "transparent")};
	border-radius: 2px;
	border: none;
	cursor: pointer;
	display: flex;
	height: 24px;
	justify-content: center;
	min-width: 24px;
	padding: 0;
	width: 24px;

	&:hover {
		background-color: #f7f7f7;
	}
`;

type MockBrowserProps = { isMobile?: boolean };
const MockBrowser = styled.div<MockBrowserProps>`
	${({ isMobile }) => css`
		border-radius: ${isMobile ? "48px" : "8px"};
		border: ${isMobile ? "12px solid white" : "0px solid white"};
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		transition: all 0.3s ease-in-out;
		width: ${isMobile ? "262px" : "100%"};

		padding: ${!isMobile && "0 64px"};
		margin-bottom: 16px;

		@media (max-width: 768px) {
			padding: ${!isMobile && "0 16px"};
		}
	`}
`;

const MockBrowserHeader = styled.div<MockBrowserProps>`
	${({ isMobile }) => css`
		background: ${isMobile ? "rgb(236, 236, 236)" : "white"};
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		display: flex;
		height: ${isMobile ? "36px" : "24px"};
		position: relative;
		transition: all 0.3s ease-in-out;
		width: 100%;
	`}
`;

const MockBrowserControls = styled.div<MockBrowserProps>`
	align-items: center;
	display: flex;
	gap: 8px;
	height: 100%;
	left: 0;
	padding-left: 8px;
	position: absolute;
`;

type MockBrowserDotProps = MockBrowserProps & { show?: boolean };
const MockBrowserDot = styled.div<MockBrowserDotProps>`
	${({ isMobile, show }) => css`
		background: rgb(217, 217, 217);
		border: ${isMobile ? "3px solid rgb(235, 237, 245);" : "0px solid white"};
		border-radius: 50%;
		box-sizing: content-box;
		display: block;
		height: 8px;
		left: 6px;
		opacity: ${show ? "1" : "0"};
		top: 6px;
		width: 8px;

		${isMobile &&
		css`
			margin-top: auto;
			margin-bottom: auto;
			margin-right: 8px;
		`}

		transition: all 0.3s ease-in-out;
	`}
`;

const MockBrowserAddressBar = styled.div<MockBrowserProps>`
	${({ isMobile }) => css`
		background: rgb(245, 245, 245);
		border-radius: 99px;
		display: flex;
		height: ${isMobile ? "22px" : "12px"};
		justify-content: flex-end;
		margin: auto;
		transition: all 0.3s ease-in-out;
		width: ${isMobile ? "80px" : "240px"};
	`}
`;

const Iframe = styled.iframe`
	border: none;
	flex-grow: 1;
	min-height: 462px;
	width: 100%;
	border-bottom-left-radius: var(--rounded-md);
	border-bottom-right-radius: var(--rounded-md);
`;

type PreviewMockSiteProps = {
	banner?: ReactNode | null;
	children: ReactNode;
	darkBackground?: boolean;
	isMobile?: boolean;
	linkOut?: string;
	noGutters?: boolean; // Remove the side padding from the window
	previewMode: PreviewMode;
	setPreviewMode: (mode: PreviewMode) => void;
	noScroll?: boolean;
};

export const PreviewMockSite: FC<PreviewMockSiteProps> = ({
	banner,
	children,
	darkBackground,
	linkOut,
	noGutters,
	previewMode,
	setPreviewMode,
	noScroll = false
}) => {
	const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);
	const mountNode = iframeRef?.contentWindow?.document?.body;
	const isMobile = previewMode === "phone";

	// Cant use emotion as this goes into the iframe
	const mock = (
		<div
			id="BrowserMockContent"
			style={{
				backgroundColor: isMobile
					? "rgb(236, 236, 236)"
					: darkBackground
					? "rgba(142, 142, 142)"
					: "rgba(201, 201, 201)",
				borderRadius: "0px 0px 8px 8px",
				display: "flex",
				flexDirection: "column",
				height: "100%",
				overflow: noScroll ? "none" : "auto",
				padding: noGutters ? "0" : isMobile ? "0 24px" : "0 32px",
				transition: "all 0.3s ease-in-out"
			}}
		>
			<style>
				{`
				button {
					background-color: black;
					border: none;
					border-radius: ${isMobile ? "8px" : "18px"};
					color: white;
					cursor: pointer;
					font-size: ${isMobile ? "12px" : "16px"};
					line-height: ${isMobile ? "15px" : "20px"};
					margin: auto;
					margin-bottom:${isMobile ? "24px" : "64px"};
					margin-top: ${isMobile ? "0px" : "36px"};
					padding: ${isMobile ? "24px 15px" : "8px 32px"};
					text-transform: uppercase;

					transition: all 0.3s ease-in-out;
				}

				.skeleton {
					background-color: rgb(217, 217, 217);
					border-radius: 4px;
					flex: 1;
					margin-bottom: 24px;
					max-height: 76px;
					min-height: 34px;
					width: 100%;
				}

				.shrink {
					max-height: 34px;
				}

				.no-gutter {
					margin-bottom: 0;
				}

				.tall {
					min-height: 76px;
				}
			`}
			</style>
			{children}
		</div>
	);

	if (mountNode) {
		mountNode.style.height = "100%";
		mountNode.style.margin = "0";
		mountNode.style.overflow = "hidden";
		mountNode.setAttribute("data-hx-console-widget-demo", "true");
	}

	return (
		<PreviewContainer>
			<Header>
				<PreviewActionsContainer className="PreviewActionsContainer">
					<ToggleButton isActive={previewMode === "desktop"} onClick={() => setPreviewMode("desktop")}>
						<DesktopIcon small />
					</ToggleButton>
					<ToggleButton isActive={previewMode === "phone"} onClick={() => setPreviewMode("phone")}>
						<MobileIcon small />
					</ToggleButton>
					{linkOut && (
						<ToggleButton onClick={() => window.open(linkOut, "_blank")}>
							<LinkOutIcon size="16px" />
						</ToggleButton>
					)}
				</PreviewActionsContainer>
				{banner}
				<div />
			</Header>
			<MockBrowser className="MockBrowser" isMobile={isMobile}>
				<MockBrowserHeader className="MockBrowserHeader" isMobile={isMobile}>
					<MockBrowserControls className="MockBrowserControls">
						<MockBrowserDot className="MockBrowserDot" show={!isMobile} />
						<MockBrowserDot className="MockBrowserDot" show={!isMobile} />
						<MockBrowserDot className="MockBrowserDot" show={!isMobile} />
					</MockBrowserControls>
					<MockBrowserAddressBar className="MockBrowserAddressBar" isMobile={isMobile}>
						<MockBrowserDot className="MockBrowserDot" isMobile={isMobile} show={isMobile} />
					</MockBrowserAddressBar>
				</MockBrowserHeader>
				<Iframe ref={(ref) => setIframeRef(ref)} sandbox="allow-scripts allow-same-origin allow-forms">
					{mountNode && (createPortal(mock, mountNode) as ReactNode)}
				</Iframe>
			</MockBrowser>
		</PreviewContainer>
	);
};
