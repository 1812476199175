import { Switch } from "@/ui/antd";
import InputWrapper from "./helpers/InputWrapper";

const SwitchField = ({
	input,
	label,
	description,
	inline,
	required,
	disabled,
	labelAfter,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick,
	meta: { touched, error },
	invert = false,
	style
}) => {
	let value = input.value === null || input.value === "" ? false : input.value;
	if (value === "true") {
		value = true;
	}
	if (value === "false") {
		value = false;
	}

	if (invert) {
		value = !value;
	}

	return (
		<InputWrapper
			label={label}
			labelAfter={labelAfter}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<Switch
				id={input.name}
				{...input}
				onChange={(checked) => {
					input.onChange(invert ? !checked : checked);
				}}
				onBlur={() => null}
				disabled={disabled}
				checked={value}
				aria-label={`${label}, current value: ${value}`}
				style={style}
			/>
		</InputWrapper>
	);
};

export default SwitchField;
