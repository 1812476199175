import { CodeBlock, CopyButton } from "@/ui";
import * as Icons from "@/ui/Icons";
import { Flex, Input, message, Table } from "@/ui/antd";
import { FC, useState } from "react";

const props = `type IconProps = {
	size?: number;
};`;

const sample = `import { EditIcon } from "@/ui";

<EditIcon size="18px" />
<EditIcon size="50%" />`;

const columns = [
	{ dataIndex: "icon", key: "icon", title: "Icon", width: 60 },
	{ dataIndex: "usage", key: "usage", title: "Usage" },
	{ dataIndex: "import", key: "import", title: "Import" }
];

export const IconsPage: FC = () => {
	const [filter, setFilter] = useState("");
	const items = Object.entries(Icons).filter(([iconName]) => iconName.toLowerCase().includes(filter.toLowerCase()));

	const dataSource = items.map(([iconName, Icon]) => {
		const usageCopy = `<${iconName} />`;
		const importCopy = `import { ${iconName} } from "@/ui";`;
		return {
			key: iconName,
			icon: <Icon size="24px" />,
			usage: (
				<CopyButton
					onClick={() =>
						message.success(
							<>
								Copied: <code>{usageCopy}</code>
							</>
						)
					}
					toCopy={usageCopy}
				>
					<code>{usageCopy}</code>
				</CopyButton>
			),
			import: (
				<CopyButton
					onClick={() =>
						message.success(
							<>
								Copied: <code>{importCopy}</code>
							</>
						)
					}
					toCopy={importCopy}
				>
					<code>{importCopy}</code>
				</CopyButton>
			)
		};
	});

	return (
		<Flex gap="lg" vertical>
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
			<br />
			<h2>List</h2>
			<Flex vertical gap="lg">
				<Input
					onChange={(e) => setFilter(e.target.value)}
					placeholder="Filter"
					style={{ position: "sticky", top: -24, background: "var(--surface1)", zIndex: 1 }}
					value={filter}
				/>
				<Table columns={columns} dataSource={dataSource} pagination={false} sticky={{ offsetHeader: 12 }} />
			</Flex>
		</Flex>
	);
};
