import { ChevronDownIcon } from "@/ui";
import { Collapse, CollapseProps } from "@/ui/antd";
import styled from "@emotion/styled";
import { FC, ReactNode } from "react";

const StyledAccordion = styled(Collapse)`
	padding: 9px 0 !important;
	width: 100%;

	.ant-collapse-header,
	.ant-collapse-content-box {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.ant-collapse-arrow {
		right: 0 !important;
	}
`;

const getPanelStyle = ({ borderBottom, paddingBottom }: PanelProps): React.CSSProperties => ({
	padding: "12px 0",
	borderBottom: borderBottom ? "var(--border1)" : "",
	paddingBottom: paddingBottom ? `${paddingBottom}px` : ""
});

type PanelProps = {
	borderBottom?: boolean;
	children: ReactNode;
	header: ReactNode;
	key: string;
	paddingBottom?: number;
};

type AccordionProps = {
	defaultOpen?: boolean;
	panels: PanelProps[];
};

/**
 * Accordion component
 * @param defaultOpen - Whether the accordion should be open by default
 * @param panels - Array of panel props
 *
 * @example
 * <Accordion
 *   defaultOpen
 *   panels={[
 *     {
 *         borderBottom: true,
 *         children: "Content 1",
 *         header: "Panel 1",
 *         key: "panel 1",
 *         paddingBottom: 10
 *     }
 *   ]}
 * />
 */
export const Accordion: FC<AccordionProps> = ({ defaultOpen = false, panels }) => {
	const items: CollapseProps["items"] = panels.map((panel) => ({
		label: panel.header,
		style: getPanelStyle(panel),
		...panel
	}));

	return (
		<StyledAccordion
			accordion
			defaultActiveKey={defaultOpen ? [panels[0].key] : undefined}
			expandIconPosition="end"
			expandIcon={({ isActive }) => (
				<ChevronDownIcon
					small
					style={{
						transform: `scaleY(${isActive ? -1 : 1}) translateY(${isActive ? 50 : -50}%)`,
						transition: "transform 0.2s"
					}}
				/>
			)}
			ghost
			style={{ padding: "0px" }}
			items={items}
		/>
	);
};
