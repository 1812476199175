import { ForgotPassword } from "@/auth/ForgotPassword";
import { Login } from "@/auth/Login";
import { MicrosoftAuth } from "@/auth/MicrosoftAuth";
import { ResetPassword } from "@/auth/ResetPassword";
import { Signin } from "@/auth/Signin";
import { Signup } from "@/auth/Signup";
import { VerifyAccount } from "@/auth/VerifyAccount";
import { VerifyEmail } from "@/auth/VerifyEmail";
import Authorize from "@/authorization/Authorize";
import HubspotTracking from "@/components/HubspotTracking";
import MarkerIO from "@/components/MarkerIO";
import { getConfig } from "@/config";
import Console from "@/console/Console";
import { ConnectStripe } from "@/events/event/payments/ConnectStripe";
import { useAuth } from "@/hooks/useAuth";
import { useReauthentication } from "@/hooks/useReauthentication";
import NotFound from "@/NotFound";
import { useAppSelector } from "@/state/hooks";
import { WinkLoader } from "@/ui";
import ConnectHubspot from "@/users/hubspot/ConnectHubspot";
import { ConnectMailchimp } from "@/users/mailchimp/ConnectMailchimp";
import OnboardingOrganiser from "@/users/onboarding/Onboarding";
import { ConnectSalesforce } from "@/users/salesforce/ConnectSalesforce";
import { initialize } from "@hx/analytics";
import "antd/dist/reset.css";
import { FC, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";

type PrivateRouteProps = {
	element: JSX.Element;
};

const PrivateRoute: FC<PrivateRouteProps> = ({ element }) => {
	const { authorized } = useAuth();
	const user = useAppSelector((state) => state.auth?.user);
	const location = useLocation();

	const requiresVerification = authorized && user && user.emailValid === false && user.emailVerified === false;
	const pathIsNotAtVerify =
		!location.pathname.includes("verify-account") && !location.pathname.includes("verify-email");

	return (
		<>
			{authorized === false && (
				<Navigate
					to={{
						pathname: "/signin",
						search: `?from=${encodeURIComponent(location.pathname + location.search)}`
					}}
					state={{ from: location }}
				/>
			)}
			{authorized && requiresVerification && pathIsNotAtVerify && <Navigate to="/verify-account" />}
			{authorized && element}
		</>
	);
};

const App: FC = () => {
	const { userChecked, authorized } = useAuth();
	const { ReauthenticationModal } = useReauthentication();

	useEffect(() => {
		initialize(getConfig("USERFLUX_KEY"), "console");
	}, []);

	const winkLoader = useMemo(() => <WinkLoader fullscreen loading={false} />, [userChecked, authorized]);

	return (
		<>
			{winkLoader}
			{userChecked && (
				<>
					<MarkerIO />
					<HubspotTracking />
					<Routes>
						<Route path="/" element={<Navigate to="/console/dashboard" />} />
						<Route path="/signin" element={<Signin />} />
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/account-management" element={<ResetPassword />} />
						<Route path="/authorize" element={<Authorize />} />
						<Route path="/sso/microsoft" element={<MicrosoftAuth />} />
						<Route path="/verify-account/*" element={<PrivateRoute element={<VerifyAccount />} />} />
						<Route path="/verify-email/*" element={<PrivateRoute element={<VerifyEmail />} />} />
						<Route path="/onboarding-organiser/:step" element={<PrivateRoute element={<OnboardingOrganiser />} />} />
						<Route path="/console/*" element={<PrivateRoute element={<Console />} />} />
						<Route path="/connect-stripe" element={<PrivateRoute element={<ConnectStripe />} />} />
						<Route path="/connect-salesforce" element={<PrivateRoute element={<ConnectSalesforce />} />} />
						<Route path="/connect-hubspot" element={<PrivateRoute element={<ConnectHubspot />} />} />
						<Route path="/connect-mailchimp" element={<PrivateRoute element={<ConnectMailchimp />} />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
					{ReauthenticationModal}
				</>
			)}
		</>
	);
};

export default App;
