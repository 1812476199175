import { ConfigPanelLayout } from "@/features/collections/configPanel/ConfigPanelLayout";
import { ConfigPanelSettings } from "@/features/collections/configPanel/ConfigPanelSettings";
import { ConfigPanelWidget } from "@/features/collections/configPanel/ConfigPanelWidget";
import { ConfigPanelEvents } from "@/features/collections/configPanel/eventsTab/ConfigPanelEvents";
import { ConfigPanelInfo } from "@/features/collections/configPanel/infoTab/ConfigPanelInfo";
import { BulletPointsIcon, CalendarIcon, CodeIcon, LayoutIcon, SettingsIcon } from "@/ui";
import { Form, FormInstance } from "@/ui/antd";
import styled from "@emotion/styled";
import { useWatch } from "antd/lib/form/Form";
import { FC } from "react";

const ConfigPanelWrapper = styled.div`
	flex: 1 0 25%;
	min-width: min(372px, 80vw);
	overflow-y: scroll;
	padding: 16px;
	width: 100%;
`;

export type CollectionsConfigPanelKey = "events" | "settings" | "layout" | "info" | "widget";

export const configMenuItems: Record<
	CollectionsConfigPanelKey,
	{ key: CollectionsConfigPanelKey; label: string; icon: JSX.Element }
> = {
	events: {
		icon: <CalendarIcon size="20px" />,
		key: "events",
		label: "Events"
	},
	info: {
		key: "info",
		label: "Info",
		icon: <BulletPointsIcon size="20px" />
	},
	layout: {
		key: "layout",
		label: "Layout",
		icon: <LayoutIcon size="20px" />
	},
	settings: {
		key: "settings",
		label: "Settings",
		icon: <SettingsIcon size="20px" />
	},
	widget: {
		key: "widget",
		label: "Widget",
		icon: <CodeIcon size="20px" />
	}
};

type ConfigPanelProps = {
	form: FormInstance;
	selectedPanelKey: CollectionsConfigPanelKey;
};

export const CollectionsConfigPanel: FC<ConfigPanelProps> = ({ form, selectedPanelKey }) => {
	const formValues = useWatch([], form);

	const isEventsPanel = formValues && selectedPanelKey === "events";
	const isInfoPanel = formValues && selectedPanelKey === "info";
	const isLayoutPanel = formValues && selectedPanelKey === "layout";
	const isSettingsPanel = formValues && selectedPanelKey === "settings";
	const isWidgetPanel = formValues && selectedPanelKey === "widget";

	return (
		<ConfigPanelWrapper>
			<Form form={form} layout="vertical" initialValues={formValues}>
				<ConfigPanelEvents open={isEventsPanel} form={form} />
				<ConfigPanelInfo open={isInfoPanel} form={form} />
				<ConfigPanelLayout open={isLayoutPanel} form={form} />
				<ConfigPanelSettings open={isSettingsPanel} form={form} />
				<ConfigPanelWidget open={isWidgetPanel} form={form} />

				{/* Register externally managed fields so antd returns them in the diff for making save available */}
				<Form.Item name={["name"]} hidden />
			</Form>
		</ConfigPanelWrapper>
	);
};
