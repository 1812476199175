import { getConfig, getFeatureFlags } from "@/config";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { SignInWithUtsSSOParams, useSingleSignOn } from "@/hooks/useSingleSignOn";
import { initConversationsApi, initIntercom, initUserGuiding, trackLogin } from "@/libs/tracking";
import axios from "@/services/AxiosWrapper";
import TokenService from "@/services/TokenService";
import { afterLoginActions, afterSignupActions, CLEAR_STATE } from "@/state/actions/authActions";
import { useAppSelector } from "@/state/hooks";
import { setAssumingIdentity } from "@/state/slices/auth";
import { trpc } from "@/trpc";
import { CreateUserInputSchema, CurrentUserSchema } from "@hx/console";
import { notification } from "antd";
import { FirebaseError, getApps, initializeApp } from "firebase/app";
import {
	confirmPasswordReset,
	createUserWithEmailAndPassword,
	fetchSignInMethodsForEmail,
	User as FirebaseUser,
	getAuth,
	getIdToken,
	onAuthStateChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export const parseErrorMessage = (err: { code: string; message: string }) => {
	switch (err.code) {
		case "auth/user-not-found":
			return "This account does not exist. Please select Sign Up to create an account.";
		case "auth/wrong-password":
			return "The username or password is invalid.";
		case "auth/too-many-requests":
			return "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
		default:
			return err.message;
	}
};

export type CreateUserInput = Omit<CreateUserInputSchema, "id">;

const isHumanitixUser = (email: string): boolean => {
	const humanitixPrimaryEmails: string[] = ["@humanitix.com", "@humanitix.com.au", "@humanitix.co.nz"];
	return humanitixPrimaryEmails.some((domain) => email.endsWith(domain));
};

export const useAuth = () => {
	const navigate = useNavigate();
	const [authorized, setAuthorized] = useState<boolean>();
	const [userChecked, setUserChecked] = useState<boolean>(false);
	const [skipUserRefetch, setSkipUserRefetch] = useState<boolean>(false);

	const dispatch = useDispatch();
	const user = useAppSelector((state) => state.auth.user);
	const assumingIdentity = useAppSelector((state) => state.auth.assumingIdentity);
	const { signInWithUtsSSO, linkGoogleSSO, unlinkGoogleSSO, signInWithGoogleSSO } = useSingleSignOn();
	const { refreshUserStateData } = useCurrentUser();

	const { mutateAsync } = trpc.users.signUp.useMutation();

	const initTracking = () => {
		const { id, email, firstName, lastName, verified, intercomHash, createdAt, location } = user ?? {};
		if (email) initConversationsApi({ email });

		if (id && createdAt && location) initUserGuiding({ id, createdAt, location });

		if (firstName && lastName && email && intercomHash && createdAt) {
			initIntercom({
				assumingIdentity,
				firstName,
				lastName,
				email,
				intercomHash,
				createdAt,
				verified
			});
		}

		if (id && email && location) trackLogin({ id, email, location });
	};

	const fetchUser = async () => {
		const loggedInUser = getAuth().currentUser;
		if (!loggedInUser) return;

		if (!user && !!TokenService.getToken()) {
			await refreshUserStateData();
		}
	};

	/**
	 * Fires when the page loads, as well as any time a user is logged in or logged out.
	 */
	const onAuthChange = async (firebaseUser: FirebaseUser | null) => {
		setUserChecked(true);
		const isAssumingIdentity = localStorage.getItem("x-identity");
		dispatch(setAssumingIdentity(!!isAssumingIdentity));

		if (firebaseUser) {
			setAuthorized(true);
			const userToken = await getIdToken(firebaseUser, true);
			TokenService.persistToken(userToken, dispatch);

			// Skip user refetch when signing in with SSO. The user will be fetched after creation in the SSO flow.
			const shouldFetchUser = !skipUserRefetch && !user;
			if (shouldFetchUser) {
				await fetchUser();
			}

			initTracking();
		} else {
			setAuthorized(false);
		}
	};

	useEffect(() => {
		const apps = getApps();
		const isAppInitialized = apps.length > 0;

		if (!isAppInitialized) {
			initializeApp({
				apiKey: getConfig("FIREBASE_API_KEY"),
				authDomain: getConfig("FIREBASE_AUTH_DOMAIN"),
				databaseURL: getConfig("FIREBASE_DATABASE_URL"),
				projectId: getConfig("FIREBASE_PROJECT_ID")
			});
		}

		const unsubscribe = onAuthStateChanged(getAuth(), onAuthChange);

		return () => unsubscribe();
	}, []);

	const onAuthSuccess = async (user: FirebaseUser): Promise<void> => {
		const userToken = await getIdToken(user, true);
		TokenService.persistToken(userToken, dispatch);
		setAuthorized(true);
	};

	const signIn = async (
		{ email, password }: { email: string; password: string },
		{ from }: { from: string | null }
	) => {
		try {
			const userCredential = await signInWithEmailAndPassword(getAuth(), email, password);
			await signInSuccess(userCredential.user, { from });
		} catch (err: unknown | FirebaseError) {
			const isFirebaseError = !!(err as FirebaseError).code;
			let message = (err as Error).message;

			if (isFirebaseError) {
				message = parseErrorMessage(err as FirebaseError);
			}

			notification.error({
				message: "Oops",
				description: message
			});

			throw err;
		}
	};

	const signInSuccess = async (user: FirebaseUser, options: { from: string | null }): Promise<void> => {
		await onAuthSuccess(user);

		if (assumingIdentity) {
			const userToken = await getIdToken(user, true);
			TokenService.persistToken(userToken, dispatch);

			notification.success({
				message: "Success",
				description: "You have successfully assumed the user identity"
			});
		}

		const userData = await refreshUserStateData();
		if (userData) {
			initTracking();
			afterLoginActions(navigate, options.from);
		}
	};

	const signUp = async (input: CreateUserInput, password: string, token?: string) => {
		try {
			const firebaseUser = await createUserWithEmailAndPassword(getAuth(), input.email, password);
			const signupPayload: CreateUserInputSchema = {
				...input,
				id: firebaseUser.user.uid,
				token
			};

			const userData = await mutateAsync(signupPayload);
			const userCredential = await signInWithEmailAndPassword(getAuth(), input.email, password);
			await onAuthSuccess(userCredential.user);

			if (userData) {
				initTracking();
				afterSignupActions(userData as CurrentUserSchema, dispatch, navigate);
			}

			return userData;
		} catch (err) {
			notification.error({
				message: "Oops",
				description: (err as Error).message
			});

			throw err;
		}
	};

	const signOut = async (): Promise<void> => {
		TokenService.clearToken(dispatch);
		setAuthorized(false);
		dispatch({ type: CLEAR_STATE });
		await getAuth().signOut();
	};

	const assumeIdentity = async (userId: string): Promise<void> => {
		axios.defaults.headers.common["x-identity"] = userId;
		localStorage.setItem("x-identity", userId);
		dispatch(setAssumingIdentity(true));

		await signOut();

		/**
		 * redirect to login screen to ensure that
		 * all redux form data is set for the user of choice
		 */
		navigate("/signin");
		notification.success({
			message: "Success",
			description: "Please re-sign in with your admin account to assume the user identity."
		});
	};

	const discardIdentity = (): void => {
		delete axios.defaults.headers.common["x-identity"];
		localStorage.removeItem("x-identity");
		dispatch(setAssumingIdentity(false));

		notification.success({
			message: "Success",
			description: "Please re-sign in with your admin account to discard the user identity."
		});
		navigate("/signin");
	};

	const forgotPassword = async (
		email: string,
		successMessage: string,
		errorMessageOverride?: string
	): Promise<void> => {
		try {
			await sendPasswordResetEmail(getAuth(), email);
			notification.success({
				message: "Sent",
				description: successMessage
			});
			navigate("/signin");
		} catch (err) {
			if (errorMessageOverride) {
				notification.error({ message: "Error", description: errorMessageOverride });
				return;
			}

			notification.error({ message: "Error", description: (err as Error).message });
		}
	};

	const resetPassword = async (code: string, email: string): Promise<void> => {
		try {
			await confirmPasswordReset(getAuth(), code, email);

			notification.success({
				message: "Success",
				description: "Your password has been reset."
			});

			navigate("/signin");
		} catch (err) {
			notification.error({ message: "Error", description: (err as Error).message });
		}
	};

	const checkSignInMethods = async (email?: string | null): Promise<string[]> => {
		if (!email) return [];
		const providers = await fetchSignInMethodsForEmail(getAuth(), email);

		if (email.indexOf("uts.edu.au") > -1) {
			return ["uts.sso", ...providers];
		}

		return providers;
	};

	const checkEmailExists = async (
		email: string
	): Promise<{
		exists: boolean;
		preferredSignInMethod?: string | undefined;
	}> => {
		const availableProviders = await checkSignInMethods(email);

		const isInternalUser = email.indexOf("humanitix.com") > -1;
		const preferredSignInMethod = await getPreferredSignInMethod(availableProviders, isInternalUser);

		return {
			exists: availableProviders.length > 0,
			preferredSignInMethod
		};
	};

	const getPreferredSignInMethod = async (availableMethods: string[], isInternalUser: boolean): Promise<string> => {
		const { allowGoogleSSO } = getFeatureFlags(isInternalUser);

		// if the user is a UTS user, we want to default to UTS SSO
		if (availableMethods.includes("uts.sso")) return "uts.sso";

		// if the user has google linked, we want to default to google (SSO takes precedence over password)
		if (availableMethods.includes("google.com") && allowGoogleSSO) return "google.com";

		// if the user has password, we want to default to password
		if (availableMethods.includes("password")) return "password";

		return "";
	};

	/**
	 * Use the useSingleSignOn hook to sign in with UTS SSO.
	 * There is a listener for the auth state change that will set the user in the redux store.
	 * In the case that we're creating a new user, we don't want the listener to fetch the user before the user is created.
	 * We set the skipUserRefetch state to true before calling signInWithUtsSSO and then set it back to false after the user is created.
	 */
	const microsoftSignIn = async (params: SignInWithUtsSSOParams, { from }: { from: string | null }): Promise<void> => {
		try {
			setSkipUserRefetch(true);
			const { isNewUser, user: firebaseUser } = await signInWithUtsSSO(params);
			await onAuthSuccess(firebaseUser);
			const user = await refreshUserStateData();
			initTracking();
			setSkipUserRefetch(false);

			// if we were unable to retrieve user details, they will have already been notified to contact support.
			if (!user) {
				navigate("/signin");
				return;
			}

			if (isNewUser) {
				afterSignupActions(user, dispatch, navigate);
			} else {
				afterLoginActions(navigate, from);
			}

			return;
		} catch (err) {
			notification.error({
				message: "Error",
				description: "Sign in error."
			});
			navigate("/signin");
		}
	};

	const googleSSOLink = async (): Promise<void> => {
		try {
			if (!user?.email) {
				throw new Error("You must be signed in to link your Google account");
			}

			if (!isHumanitixUser(user.email)) {
				notification.error({
					message: "Error",
					description: "Please sign in with your Humanitix account to link your Google account."
				});
				return;
			}
			const { user: firebaseUser } = await linkGoogleSSO();
			await onAuthSuccess(firebaseUser);

			notification.success({
				message: "Success",
				description: "Google account linked."
			});

			await refreshUserStateData();
		} catch (err) {
			notification.error({
				message: "Oops",
				description: parseErrorMessage(err as FirebaseError)
			});
			throw err;
		}
	};

	const googleSSOUnlink = async (): Promise<void> => {
		try {
			await unlinkGoogleSSO();

			notification.success({
				message: "Success",
				description: "Sign-in method removed."
			});

			await refreshUserStateData();
		} catch (err) {
			notification.error({
				message: "Error",
				description: "Unlinking error. Please try again later."
			});
		}
	};

	/**
	 * NOTE: This does NOT support creating new users with Google SSO. For that, we need to create users in the htix DB and so fourth.
	 * This will be handled post-mvp for SSO.
	 */
	const launchGoogleSSO = async ({ from, email }: { from: string | null; email: string }): Promise<void> => {
		const firebaseUser = await signInWithGoogleSSO({ expectedEmailAddress: email });
		await onAuthSuccess(firebaseUser);

		const userData = await refreshUserStateData();
		if (userData) {
			initTracking();
			afterLoginActions(navigate, from);
		}
	};

	return {
		assumeIdentity,
		assumingIdentity,
		authorized,
		checkEmailExists,
		discardIdentity,
		forgotPassword,
		googleSSOLink,
		googleSSOUnlink,
		launchGoogleSSO,
		microsoftSignIn,
		refreshUserStateData,
		resetPassword,
		signIn,
		signInSuccess,
		signOut,
		signUp,
		userChecked
	};
};
