import RichTextArea from "@/components/RichTextArea";
import { CollectionSlugField } from "@/features/collections/configPanel/infoTab/CollectionSlugField";
import { SocialLinkList } from "@/features/collections/configPanel/infoTab/SocialLinkList";
import { useHostProfiles } from "@/hooks/useHostProfiles";
import { Accordion, FormLabel, ImageUploader, ImageUploaderResult } from "@/ui";
import { Form, FormInstance, Select } from "@/ui/antd";
import { Collection } from "@hx/console";
import { useWatch } from "antd/lib/form/Form";
import { FC, useEffect } from "react";

type ConfigPanelInfoProps = {
	form: FormInstance;
	open: boolean;
};

export const ConfigPanelInfo: FC<ConfigPanelInfoProps> = ({ form, open }) => {
	const formValues = useWatch<Collection>([], form);

	const hostProfiles = useHostProfiles(formValues?.info.hostProfile ? [formValues?.info.hostProfile] : []);

	useEffect(() => {
		return () => hostProfiles.reset();
	}, []);

	return (
		<div style={{ display: open ? "block" : "none" }}>
			<CollectionSlugField form={form} />
			<br />
			{/* HOST PROFILE */}
			<FormLabel>Host profile</FormLabel>
			<p>Let your guests know who owns the collection.</p>
			<Form.Item name={["info", "hostProfile"]}>
				<Select
					allowClear
					filterOption={false}
					onChange={() => hostProfiles.search("", null, true)}
					onSearch={hostProfiles.search}
					options={hostProfiles.options}
					placeholder="Select a host profile"
					showSearch
					value={formValues?.info.hostProfile}
				/>
			</Form.Item>
			{/* DESCRIPTION */}
			<FormLabel>Collection description</FormLabel>
			<p>What is your collection about?</p>
			{/* Would be great to refactor redactor - until then, this abomination */}
			<style>{`
				.redactor-box.redactor-styles-on.redactor-toolbar-on {
					border-color: #dedede !important;
					border-radius: 8px;
					overflow: hidden;
				}
			`}</style>
			<Form.Item
				name={["info", "description"]}
				rules={[{ max: 8000, message: "Description must be less than 8000 characters" }]}
			>
				<RichTextArea value={formValues?.info.description} />
			</Form.Item>
			{/* IMAGE UPLOAD */}
			<Form.Item label={<FormLabel>Upload banner image</FormLabel>} name={["info", "image"]}>
				<ImageUploader canva defaultImage={formValues?.info.image as ImageUploaderResult} />
			</Form.Item>
			<Accordion
				defaultOpen={true}
				panels={[
					// SOCIAL LINKS
					{
						header: <FormLabel>Social Links</FormLabel>,
						key: "1",
						children: <SocialLinkList form={form} />
					}
				]}
			/>
		</div>
	);
};
