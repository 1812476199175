import { BuyTicketsOrHelp } from "@/auth/components/BuyTicketsOrHelp";
import { ForgotPasswordLink, NeedANewAccount } from "@/auth/components/Links";
import { TwoColumnPageWrap } from "@/auth/components/TwoColumnPageWrap";
import { H1, H2, P } from "@/components/text/Text";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "@/ui/Button";
import { LockOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Checkbox, Form, Input } from "antd";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const WelcomeHeader = styled.div`
	margin-bottom: 24px;
`;

const welcomeTextStyle = {
	fontWeight: "600",
	marginBottom: 4
};

export const Login: FC = () => {
	const { signIn } = useAuth();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const from = searchParams?.get("from");
	const email = searchParams?.get("email");

	const [loginForm] = Form.useForm();

	useEffect(() => {
		if (!email) {
			const path = `/signin`;
			const params = from ? `?from=${from}` : "";
			navigate(path, { state: params });
		}
	}, [email]);

	const handleLoginError = () => {
		setIsSubmitting(false);
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);

		const formValues = loginForm.getFieldsValue(true);

		if (formValues.rememberMe) {
			localStorage.setItem("email", formValues.email);
		}

		await signIn(
			{
				email: formValues.email,
				password: formValues.password
			},
			{ from }
		).catch(handleLoginError);
	};

	return (
		<TwoColumnPageWrap>
			<H1>Login</H1>

			<P center>Enter your email address below to log in to an existing account or sign up with a new one.</P>

			<Form
				autoComplete="off"
				form={loginForm}
				initialValues={{
					rememberMe: true,
					email
				}}
				layout="vertical"
				style={{
					marginBottom: 48,
					marginTop: 24,
					width: "100%"
				}}
				onFinish={handleSubmit}
			>
				<WelcomeHeader>
					<P style={welcomeTextStyle}>Welcome back,</P>
					<H2>{email}</H2>
				</WelcomeHeader>

				<Form.Item
					name="password"
					label={<b>Password</b>}
					required={false}
					rules={[
						{
							whitespace: true,
							type: "string",
							required: true,
							min: 1
						}
					]}
				>
					<Input prefix={<LockOutlined />} placeholder="Password" type="password" />
				</Form.Item>

				<div style={{ display: "flex", justifyContent: "space-between", marginTop: "-24px" }}>
					<Form.Item name="rememberMe" valuePropName="checked" required={false}>
						<Checkbox defaultChecked>Remember me</Checkbox>
					</Form.Item>
					<div style={{ marginTop: "4px" }}>
						<ForgotPasswordLink />
					</div>
				</div>

				<Button
					aria-label="Log In"
					disabled={isSubmitting}
					fullWidth
					htmlType="submit"
					isLoading={isSubmitting}
					variant="primary"
				>
					Login
				</Button>
				<div style={{ marginTop: "8px" }}>
					<NeedANewAccount />
				</div>
			</Form>

			<BuyTicketsOrHelp />
		</TwoColumnPageWrap>
	);
};
