import {
	BarsOutlined,
	CalendarOutlined,
	CheckCircleFilled,
	CloseCircleFilled,
	CloseOutlined,
	DeleteOutlined,
	DesktopOutlined,
	DownOutlined,
	EditOutlined,
	HolderOutlined,
	InfoCircleFilled,
	LoadingOutlined,
	MobileOutlined,
	PictureOutlined,
	PlusOutlined,
	QrcodeOutlined,
	QuestionCircleOutlined,
	SaveOutlined,
	SettingOutlined,
	ShareAltOutlined,
	UploadOutlined,
	UpOutlined
} from "@ant-design/icons";
import { IconBaseProps as AntIconProps } from "@ant-design/icons/lib/components/Icon";
import {
	ArrowSquareOut,
	ChartBar,
	ClipboardText,
	Code,
	Couch,
	CurrencyCircleDollar,
	Layout,
	MegaphoneSimple,
	Notepad,
	Palette,
	IconProps as PhosphorIconProps,
	Receipt,
	SealQuestion,
	Ticket,
	UsersFour
} from "@phosphor-icons/react";
import { cloneElement, CSSProperties, ForwardRefExoticComponent } from "react";

// If you add more props here, you need to add them to the AntIcon and
// PhosphorIcon wrappers to handle them correctly.
type IconProps = {
	fill?: string;
	opacity?: number;
	size?: string;
	/** 14px size - ideal for icons on buttons. Takes precedence over size. */
	small?: boolean;
	style?: CSSProperties;
};

const AntDesignIcon =
	(Icon: ForwardRefExoticComponent<AntIconProps>) =>
	({ fill, opacity = 1, size = "24px", small, ...rest }: IconProps) => {
		return (
			<>
				{cloneElement(<Icon />, {
					style: {
						fontSize: small ? "14px" : size,
						color: fill,
						opacity,
						...rest.style
					},
					...rest
				})}
			</>
		);
	};

const PhosphorIcon =
	(Icon: ForwardRefExoticComponent<PhosphorIconProps>) =>
	({ fill, opacity = 1, size = "24px", small, ...rest }: IconProps) => {
		return (
			<>
				{cloneElement(<Icon />, {
					fill,
					size: small ? "14px" : size,
					style: {
						opacity,
						...rest.style
					},
					...rest
				})}
			</>
		);
	};

// Add ant design icons here following the same pattern
export const BulletPointsIcon = AntDesignIcon(BarsOutlined);
export const CalendarIcon = AntDesignIcon(CalendarOutlined);
export const CheckIcon = AntDesignIcon(CheckCircleFilled);
export const ChevronDownIcon = AntDesignIcon(DownOutlined);
export const ChevronUpIcon = AntDesignIcon(UpOutlined);
export const CloseIcon = AntDesignIcon(CloseOutlined);
export const CrossIcon = AntDesignIcon(CloseCircleFilled);
export const DeleteIcon = AntDesignIcon(DeleteOutlined);
export const DesktopIcon = AntDesignIcon(DesktopOutlined);
export const EditIcon = AntDesignIcon(EditOutlined);
export const InfoIcon = AntDesignIcon(InfoCircleFilled);
export const LoadingIcon = AntDesignIcon(LoadingOutlined);
export const MobileIcon = AntDesignIcon(MobileOutlined);
export const PictureIcon = AntDesignIcon(PictureOutlined);
export const PlusIcon = AntDesignIcon(PlusOutlined);
export const QRCodeIcon = AntDesignIcon(QrcodeOutlined);
export const SaveIcon = AntDesignIcon(SaveOutlined);
export const SettingsIcon = AntDesignIcon(SettingOutlined);
export const ShareIcon = AntDesignIcon(ShareAltOutlined);
export const SimpleQuestionIcon = AntDesignIcon(QuestionCircleOutlined);
export const UploadIcon = AntDesignIcon(UploadOutlined);
export const DragHandle = AntDesignIcon(HolderOutlined);

// Add phosphor icons here following the same pattern
export const BarChartIcon = PhosphorIcon(ChartBar);
export const ClipboardIcon = PhosphorIcon(ClipboardText);
export const CodeIcon = PhosphorIcon(Code);
export const CouchIcon = PhosphorIcon(Couch);
export const CurrencyIcon = PhosphorIcon(CurrencyCircleDollar);
export const LayoutIcon = PhosphorIcon(Layout);
export const LinkOutIcon = PhosphorIcon(ArrowSquareOut);
export const MegaphoneIcon = PhosphorIcon(MegaphoneSimple);
export const NotepadIcon = PhosphorIcon(Notepad);
export const PaletteIcon = PhosphorIcon(Palette);
export const QuestionIcon = PhosphorIcon(SealQuestion);
export const ReceiptIcon = PhosphorIcon(Receipt);
export const TicketIcon = PhosphorIcon(Ticket);
export const UsersIcon = PhosphorIcon(UsersFour);
