"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// index.ts
var dates_exports = {};
__export(dates_exports, {
  DateFormats: () => DateFormats,
  HumaniDate: () => HumaniDate,
  TimeFormats: () => TimeFormats,
  TimezoneFormats: () => TimezoneFormats
});
module.exports = __toCommonJS(dates_exports);
var import_dayjs = __toESM(require("dayjs"));
var import_advancedFormat = __toESM(require("dayjs/plugin/advancedFormat"));
var import_weekOfYear = __toESM(require("dayjs/plugin/weekOfYear"));
var import_utc = __toESM(require("dayjs/plugin/utc"));
var import_timezone = __toESM(require("dayjs/plugin/timezone"));

// Formatting.ts
var TimeFormats;
(function(TimeFormats2) {
  TimeFormats2["None"] = "none";
  TimeFormats2["Short"] = "short";
  TimeFormats2["Long"] = "long";
})(TimeFormats || (TimeFormats = {}));
var DateFormats;
(function(DateFormats2) {
  DateFormats2["Normal"] = "normal";
  DateFormats2["Pretty"] = "pretty";
  DateFormats2["Full"] = "full";
  DateFormats2["Title"] = "title";
})(DateFormats || (DateFormats = {}));
var TimezoneFormats;
(function(TimezoneFormats2) {
  TimezoneFormats2["None"] = "none";
  TimezoneFormats2["Name"] = "name";
  TimezoneFormats2["Offset"] = "offset";
})(TimezoneFormats || (TimezoneFormats = {}));
var MDYLocations = [
  "US"
];
var CalendarDateTimeUTC = "YYYYMMDD[T]HHmmss[Z]";
var timezoneFormats = {
  [TimezoneFormats.None]: "",
  [TimezoneFormats.Name]: "z",
  [TimezoneFormats.Offset]: "ZZ"
};
var MDYDateTimeFormat = "ddd MMM Do YYYY, h:mm a";
var DMYDateTimeFormat = "ddd Do MMM YYYY, h:mm a";
var dateFormats = {
  MDY: {
    [DateFormats.Normal]: "MM/DD/YYYY",
    [DateFormats.Pretty]: "MMM Do YYYY",
    [DateFormats.Full]: "dddd MMMM Do YYYY",
    [DateFormats.Title]: "MMMM Do"
  },
  DMY: {
    [DateFormats.Normal]: "DD/MM/YYYY",
    [DateFormats.Pretty]: "Do MMM YYYY",
    [DateFormats.Full]: "dddd Do MMMM YYYY",
    [DateFormats.Title]: "Do MMMM"
  }
};
var timeFormats = {
  [TimeFormats.None]: "",
  [TimeFormats.Short]: "h:mm a",
  [TimeFormats.Long]: "h:mm:ss a"
};
var Formatting = class {
  static {
    __name(this, "Formatting");
  }
  _humaniDate;
  constructor(humaniDate) {
    this._humaniDate = humaniDate;
  }
  /**
  *
  * iamkun sucks so much i hate them
  * https://github.com/iamkun/dayjs/issues/1154
  *
  * @param timezone TimezoneFormats enum
  * @returns string of timezone
  */
  timezone(timezone2) {
    let timezoneString = "";
    switch (timezone2) {
      case TimezoneFormats.Offset:
        timezoneString = this._humaniDate.dayJs.format(timezoneFormats[timezone2]);
        break;
      case TimezoneFormats.Name:
        timezoneString = this._humaniDate.dayJs.format("zzz").replace(/\s|[a-z]/g, "").replace("CUT", "UTC");
    }
    return timezoneString;
  }
  /**
  *
  * `TimeFormats.Short` = 3:45 pm
  *
  * `TimeFormats.Long` = 3:45:28 pm
  *
  * @param format `TimeFormat` enum
  * @param timezone tz string ie `Australia/Sydney`
  *
  * @returns Formatted string for time
  */
  time(format, timezone2 = TimezoneFormats.None) {
    const timeString = format === TimeFormats.None ? "" : this._humaniDate.dayJs.format(timeFormats[format]);
    const timezoneString = this.timezone(timezone2);
    return [
      timeString,
      ...timezoneString ? [
        timezoneString
      ] : []
    ].join(" ");
  }
  /**
  *
  * `DateFormats.Normal` = 23/12/1999
  *
  * `DateFormats.Pretty` = 23rd Dec 1999
  *
  * `DateFormats.Full` = Thursday 23rd Dec 1999
  *
  * @param format `DateFormats` enum
  * @returns Formatted string for the date
  */
  date(format) {
    const formatLookupTable = this._humaniDate.isMDY ? dateFormats.MDY : dateFormats.DMY;
    return this._humaniDate.dayJs.format(formatLookupTable[format]);
  }
  /**
  *
  * @param endDate end date for the date range
  * @param options Options the formator
  * 				`noTimezone` = boolean, if true will not include the timezone in the formatted string
  * @returns String for the formatted date range
  *
  *  Single Day: 				Sun, 25 Aug, 5pm - 8:30pm ASDT
  *  Single Day not this year: 	Sun, 225 Aug 2025, 5pm - 8:30pm ASDT
  *  Multi Day: 					Sun, 223 Aug, 5pm - 25 Aug, 12:30pm ASDT
  *  Multi Day not this year: 	Sun, 225 Aug, 5pm - 7 Oct, 12:30pm 2025 ASDT
  *  Multi Day Multi year: 		Sun, 225 Aug, 5pm 2024 - 1 Jan, 2am 2025 ASDT
  *
  *  For US events the Date is always after the month  Sun, Aug 25
  *  For time that are on the hour the minutes are removed 5:00 becomes 5
  */
  dateRange(endDate, options) {
    const { noTimezone } = options || {};
    const timezoneString = noTimezone ? "" : ` ${this.timezone(TimezoneFormats.Name)}`;
    const startDateDayJs = this._humaniDate.dayJs;
    const endDateDayJs = endDate.dayJs;
    const formatDateMth = this._humaniDate.isMDY ? "MMM D" : "D MMM";
    const isEndDateSameDay = startDateDayJs.isSame(endDateDayJs, "day");
    const isInSameYear = startDateDayJs.isSame(endDateDayJs, "year");
    const thisYear = new HumaniDate();
    const isInThisYear = startDateDayJs.isSame(thisYear.dayJs, "year");
    const startDay = startDateDayJs.format("ddd");
    const startDateMth = startDateDayJs.format(formatDateMth);
    const startTime = startDateDayJs.format("h:mma").replace(":00", "");
    const startYear = startDateDayJs.format("YYYY");
    const endDateMth = endDateDayJs.format(formatDateMth);
    const endTime = endDateDayJs.format("h:mma").replace(":00", "");
    const endYear = endDateDayJs.format("YYYY");
    if (isEndDateSameDay && isInThisYear) {
      return `${startDay}, ${startDateMth}, ${startTime} - ${endTime}${timezoneString}`;
    }
    if (isEndDateSameDay && !isInThisYear) {
      return `${startDay}, ${startDateMth} ${startYear}, ${startTime} - ${endTime}${timezoneString}`;
    }
    if (isInThisYear && !isEndDateSameDay && isInSameYear) {
      return `${startDay}, ${startDateMth}, ${startTime} - ${endDateMth}, ${endTime}${timezoneString}`;
    }
    if (!isInThisYear && !isEndDateSameDay && isInSameYear) {
      return `${startDay}, ${startDateMth}, ${startTime} - ${endDateMth}, ${endTime} ${startYear}${timezoneString}`;
    }
    return `${startDay}, ${startDateMth}, ${startTime} ${startYear} - ${endDateMth}, ${endTime} ${endYear}${timezoneString}`;
  }
  /**
  *
  * @param options Options the formator
  * 				`noTimezone` = boolean, if true will not include the timezone in the formatted string
  * @returns String for the formatted date
  *
  *  Single Day: 				Sun, 25 Aug, 5pm ASDT
  *  Single Day not this year: 	Sun, 225 Aug 2025, 5pm ASDT
  *
  *  For US events the Date is always after the month  Sun, Aug 25
  *  For time that are on the hour the minutes are removed 5:00 becomes 5
  */
  singleDate(options) {
    const { noTimezone } = options || {};
    const timezoneString = noTimezone ? "" : ` ${this.timezone(TimezoneFormats.Name)}`;
    const startDateDayJs = this._humaniDate.dayJs;
    const formatDateMth = this._humaniDate.isMDY ? "MMM D" : "D MMM";
    const thisYear = new HumaniDate();
    const monthGap = startDateDayJs.diff(thisYear.dayJs, "month");
    const isInThisYear = startDateDayJs.isSame(thisYear.dayJs, "year") || monthGap <= 3;
    const startDay = startDateDayJs.format("ddd");
    const startDateMth = startDateDayJs.format(formatDateMth);
    const startTime = startDateDayJs.format("h:mma").replace(":00", "");
    const startYear = startDateDayJs.format("YYYY");
    if (isInThisYear) {
      return `${startDay}, ${startDateMth}, ${startTime}${timezoneString}`;
    }
    if (!isInThisYear) {
      return `${startDay}, ${startDateMth} ${startYear}, ${startTime}${timezoneString}`;
    }
    if (isInThisYear) {
      return `${startDay}, ${startDateMth}, ${startTime}${timezoneString}`;
    }
    if (!isInThisYear) {
      return `${startDay}, ${startDateMth}, ${startTime}${timezoneString}`;
    }
    return `${startDay}, ${startDateMth}, ${startTime} ${startYear}${timezoneString}`;
  }
  calendarUTCTime() {
    return this._humaniDate.dayJs.utc().format(CalendarDateTimeUTC);
  }
  /**
  *
  * @returns String for the formatted date, ie `Fri 17th May, 1:20 pm AEST`
  */
  dateTime() {
    const timezoneString = this.timezone(TimezoneFormats.Name);
    const formatString = this._humaniDate.isMDY ? MDYDateTimeFormat : DMYDateTimeFormat;
    return [
      this._humaniDate.dayJs.format(formatString),
      timezoneString
    ].join(" ");
  }
  static datePicker(location) {
    return MDYLocations.includes(location) ? dateFormats.MDY.pretty : dateFormats.DMY.pretty;
  }
};

// Canned.ts
var Canned = class {
  static {
    __name(this, "Canned");
  }
  _humaniDate;
  constructor(humaniDate) {
    this._humaniDate = humaniDate;
  }
  /**
  *
  * @returns An invoice due date two weeks in the future formatted as `16th June 2022`
  */
  orderInvoiceDueDate() {
    return this._humaniDate.dayJs.add(14, "days").format("Do MMMM YYYY");
  }
  /**
  *
  * @returns Object containing the date and time for the aba file.
  */
  abaFileDateAndTime(headerTimeExists) {
    return {
      date: this._humaniDate.dayJs.format("DDMMYY"),
      time: headerTimeExists ? this._humaniDate.dayJs.format("HHmm") : ""
    };
  }
  /**
  *
  * @param payoutDelay number of days to delay the payout
  * @returns Date object for the payout day
  */
  payoutDate(payoutDelay) {
    return this._humaniDate.canned.addBusinessDays(payoutDelay);
  }
  /**
  *
  * @param days number of business days to add
  * @returns Date object for the original date plus the number of business days
  */
  addBusinessDays(days) {
    const SUNDAY = 0;
    const SATURDAY = 6;
    let businessDaysAdded = 0;
    let date = this._humaniDate.dayJs;
    while (businessDaysAdded < days) {
      date = date.add(1, "day");
      if (date.day() !== SUNDAY && date.day() !== SATURDAY) {
        businessDaysAdded++;
      }
    }
    return date.toDate();
  }
  /**
  *
  * Used to generate the exiry date of the facebook connection. The token comes from the following request:
  * https://developers.facebook.com/docs/facebook-login/access-tokens/refreshing/
  *
  * @param expiresIn longLifeToken.expires_in from the createUserLongLifeToken request
  * @returns ISO string of the constructed date
  */
  facebookConnectionExpiry(expiresIn) {
    return this._humaniDate.dayJs.add(Number(expiresIn), "seconds").subtract(1, "day").toISOString();
  }
  /**
  * Returns the current humanidates start and end date for that specific date
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  dayStartEnd(utcOffset = 0, preserveTimezone = false) {
    return {
      startDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).startOf("day").valueOf(),
      endDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).endOf("day").valueOf()
    };
  }
  /**
  * Returns the current humanidate + 1 day start and end date
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  tomorrowStartEnd(utcOffset = 0, preserveTimezone = false) {
    return {
      startDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).add(1, "day").startOf("day").valueOf(),
      endDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).add(1, "day").endOf("day").valueOf()
    };
  }
  /**
  * Returns the current humanidate - 1 day start and end date
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  yesterdayStartEnd(utcOffset = 0, preserveTimezone = false) {
    return {
      startDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).subtract(1, "day").startOf("day").valueOf(),
      endDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).subtract(1, "day").endOf("day").valueOf()
    };
  }
  /**
  * Returns the current humanidates weekend start and end date
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  weekendStartEnd(utcOffset = 0, preserveTimezone = false) {
    return {
      startDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).day(6).startOf("day").valueOf(),
      endDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).day(7).endOf("day").valueOf()
    };
  }
  /**
  * Returns the current humanidates week start and end date
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  weekStartEnd(utcOffset = 0, preserveTimezone = false) {
    return {
      startDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).startOf("week").valueOf(),
      endDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).endOf("week").valueOf()
    };
  }
  /**
  * Returns the current humanidates past 7 days start and end date
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  lastWeekStartEnd(utcOffset = 0, preserveTimezone = false) {
    return {
      startDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).subtract(6, "d").startOf("day").valueOf(),
      endDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).valueOf()
    };
  }
  /**
  * Returns the current humanidates + 1 week, weeks start and end date
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  nextWeekStartEnd(utcOffset = 0, preserveTimezone = false) {
    return {
      startDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).add(1, "week").startOf("week").valueOf(),
      endDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).add(1, "week").endOf("week").valueOf()
    };
  }
  /**
  * Returns the current humanidates past 30 days start and end date
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  lastMonthStartEnd(utcOffset = 0, preserveTimezone = false) {
    return {
      startDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).subtract(1, "months").add(1, "d").startOf("day").valueOf(),
      endDate: this._humaniDate.dayJs.utcOffset(utcOffset, preserveTimezone).valueOf()
    };
  }
  /**
  * Returns the a start date and end date for the month/year provided
  *
  * @param year the year in YYYY format as number
  * @param month the month as number, accepts numbers from 0 to 11
  * @returns object with startDate and endDate in number form
  */
  getMonthStartEnd(year, month) {
    return {
      startDate: this._humaniDate.dayJs.year(year).month(month).startOf("month").utc().valueOf(),
      endDate: this._humaniDate.dayJs.year(year).month(month).endOf("month").utc().valueOf()
    };
  }
  /**
  * Returns the current humanidates plus one hour for daylight savings
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  addDaylightSavings() {
    return this._humaniDate.dayJs.add(1, "h").valueOf();
  }
  /**
  * Returns the current humanidates minus one hour for daylight savings
  *
  * @param utcOffset utc offset in either minutes or hours. If less than 16 and greater than -16 its treated as hours
  * @returns object with startDate and endDate in number form
  */
  subtractDaylightSavings() {
    return this._humaniDate.dayJs.subtract(1, "h").valueOf();
  }
  /**
  *
  * @returns date portion of ISO 8601 string ie 2022-6-29
  */
  iso8601Date() {
    return this._humaniDate.dayJs.format("YYYY-MM-DD");
  }
  /**
  *
  * @returns time portion of ISO 8601 string ie 08:02:17-05:00
  */
  iso8601Time() {
    return this._humaniDate.dayJs.format("HH:mm:ssZ");
  }
  /**
  *
  * @returns iso 8601 string ie 2019-01-25T02:00:00.000Z
  */
  iso8601DateTime() {
    return this._humaniDate.dayJs.toISOString();
  }
  /**
  *
  * @returns string with timezone offset, used in JSON+ld ie 2019-07-01T10:00:00-05:00
  */
  dateTimeWithTimezoneOffset() {
    return this._humaniDate.dayJs.format("YYYY-MM-DDTHH:mm:ssZZ");
  }
  /**
  *
  * @returns rfc2616 full date string: https://www.rfc-editor.org/rfc/rfc2616.html#section-3.3.1
  */
  rfc2616() {
    return this._humaniDate.dayJs.utc().format("ddd, DD MMM YYYY HH:mm:ss") + " GMT";
  }
};

// Validation.ts
var Validation = class {
  static {
    __name(this, "Validation");
  }
  _humaniDate;
  constructor(humaniDate) {
    this._humaniDate = humaniDate;
  }
  /**
  *
  * Used to determine if the attendee actioned refund is available, checks that current
  * date is 'x' days before event date or more.
  *
  * @param refundDayLimit Number, from event.paymentOptions.refundSettings.allowBeforeDays
  * @returns boolean, true if current date is within refund period for event
  */
  isAttendeeRefundAvailable(refundDayLimit) {
    return this._humaniDate.dayJs.diff(/* @__PURE__ */ new Date(), "days", true) >= refundDayLimit;
  }
  /**
  * Checks to see if the current date is between the dates supplied. If no dates are supplied returns true.
  * If only one date is supplied it will check thats the current date is before or after the supplied one
  * accordingly
  *
  * @param startDate string or date, optional
  * @param endDate string or date, optional
  * @returns boolean, if the date is between the ranges or before/after the supplied date
  */
  inDateRange(startDate, endDate) {
    if (!startDate && !endDate) return true;
    const start = new HumaniDate(startDate);
    const end = new HumaniDate(endDate);
    if (startDate && endDate) {
      return this._humaniDate.dayJs.isAfter(start.dayJs) && this._humaniDate.dayJs.isBefore(end.dayJs);
    }
    if (startDate) {
      return this._humaniDate.dayJs.isAfter(start.dayJs);
    }
    if (endDate) {
      return this._humaniDate.dayJs.isBefore(end.dayJs);
    }
    return false;
  }
};

// To.ts
var To = class {
  static {
    __name(this, "To");
  }
  _humaniDate;
  constructor(humaniDate) {
    this._humaniDate = humaniDate;
  }
  /**
  * `"because itll be funny" - Joel 22/6/2022`
  *
  * @returns something useless
  */
  joelSON() {
    return JSON.stringify(this._humaniDate.dayJs);
  }
  /**
  *
  *  joel said to
  *
  */
  brenton() {
    throw new Error("big fucking error");
  }
  /**
  * I wonder if this will build the applications
  */
  jake() {
    console.log("REEEEEEEEEEEEEEEEEEEEEEEEE *click click*");
  }
  /**
  * `"because he is a quitter" - Joel 22/6/2022`
  */
  ameen() {
    process.exit(1);
  }
  /**
  * `"hes a happi boi" - raph 22/6/2022`
  */
  raph() {
    console.log("\u{1F60B}");
  }
  /**
  *
  * `"my internet is so bad" - scott 23/6/2022`
  *
  */
  scott() {
    console.log(`(\u256F\xB0\u25A1\xB0)\u256F\uFE35 \u253B\u2501\u253B FLIP THAT TABLE.
    \u253B\u2501\u253B \uFE35 \u30FD(\xB0\u25A1\xB0\u30FD) FLIP THIS TABLE.
    \u253B\u2501\u253B \uFE35 \uFF3C('0')/\uFF0F \uFE35 \u253B\u2501\u253B FLIP ALL THE TABLES
    \u0CA0_\u0CA0 Son... \u0CA0_\u0CA0 Put. \u0CA0__\u0CA0 The tables. \u0CA0___\u0CA0 Back.
    (\u256E\xB0-\xB0)\u256E\u2533\u2501\u2533
    (\u256F\xB0\u25A1\xB0)\u256F\uFE35 \u253B\u2501\u253B NEVER!!!!
    `);
  }
  /**
  *
  *  `"I don't want to get fired for this" - arsalan 23/6/2022`
  *
  */
  arsalan() {
    console.warn("Get the ball pass the ball");
  }
  /**
  *  `"TRUE" - ryan 23/6/2022`
  */
  ryan() {
    console.log("jake sucks and i hate him");
  }
  /**
  *
  * `"I need a quote in addition to the baquette?" - Pierre 30/8/2022`
  *
  */
  pierre() {
    console.log(`
                                                                                                                                                                
                          \u2591\u2591\u2592\u2592  \u2592\u2592  \u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2591\u2591\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592  \u2592\u2592\u2591\u2591\u2591\u2591  \u2592\u2592  \u2591\u2591\u2591\u2591\u2592\u2592  \u2592\u2592  \u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2592\u2592\u2592\u2592\u2592\u2592  \u2592\u2592  \u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592\u2592\u2592                            
                      \u2593\u2593\u2588\u2588\u2591\u2591\u2591\u2591\u2588\u2588\u2591\u2591\u2593\u2593\u2591\u2591\u2591\u2591\u2591\u2591\u2593\u2593\u2591\u2591\u2592\u2592\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2591\u2591\u2591\u2591\u2593\u2593\u2591\u2591\u2593\u2593\u2591\u2591\u2591\u2591\u2591\u2591\u2593\u2593\u2591\u2591\u2588\u2588\u2591\u2591\u2591\u2591\u2591\u2591\u2593\u2593\u2591\u2591\u2588\u2588  \u2591\u2591\u2592\u2592\u2591\u2591\u2593\u2593\u2591\u2591\u2591\u2591\u2591\u2591\u2588\u2588\u2591\u2591\u2588\u2588\u2591\u2591\u2591\u2591\u2591\u2591\u2593\u2593  \u2591\u2591\u2588\u2588\u2592\u2592\u2593\u2593\u2588\u2588\u2591\u2591                  
                \u2592\u2592\u2593\u2593\u2593\u2593\u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592\u2592\u2592\u2591\u2591\u2593\u2593\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2591\u2591\u2591\u2591\u2593\u2593\u2591\u2591\u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2593\u2593\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2593\u2593\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2588\u2588\u2592\u2592\u2592\u2592\u2591\u2591\u2591\u2591\u2593\u2593\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2593\u2593\u2591\u2591\u2592\u2592\u2591\u2591\u2593\u2593\u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592\u2593\u2593\u2592\u2592              
              \u2593\u2593\u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2588\u2588\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2588\u2588\u2593\u2593\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2592\u2592\u2588\u2588\u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2591\u2591\u2593\u2593\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2592\u2592\u2593\u2593\u2592\u2592\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2593\u2593\u2591\u2591\u2592\u2592\u2591\u2591\u2592\u2592\u2593\u2593\u2592\u2592\u2591\u2591\u2592\u2592\u2591\u2591\u2592\u2592\u2588\u2588\u2592\u2592\u2591\u2591\u2592\u2592\u2588\u2588\u2592\u2592\u2592\u2592\u2592\u2592\u2593\u2593\u2593\u2593\u2592\u2592\u2591\u2591\u2592\u2592\u2592\u2592\u2588\u2588              
              \u2593\u2593\u2592\u2592\u2592\u2592\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2592\u2592\u2593\u2593\u2593\u2593              
              \u2591\u2591\u2593\u2593\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2593\u2593\u2592\u2592\u2591\u2591              
\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2591\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2592\u2591\u2591\u2591\u2591                  
    `);
  }
  /**
  * "I'm in a depressive state today" - lindsay 11/13/2024
  */
  lindsay() {
    this.ryan = () => console.log("bleep");
    console.log("jake is great and i love him");
  }
  /**
  * "I feel immortalised" - steve 13/11/2024
  */
  phteven() {
    const meth = Object.getOwnPropertyNames(Object.getPrototypeOf(this)).map((m) => this[m]);
    meth.forEach((m) => {
      if ([
        "To",
        "phteven"
      ].includes(m.name)) return;
      try {
        console.log(`${m.name}:`);
        m.bind(this)();
      } catch (e) {
        console.log(`${m.name}'s code broke...`);
      }
    });
    console.log("     _______.___________.  ______     ______     _______  _______    ____    __    ____  ___      .______          _______.\n    /       |           | /  __  \\   /  __  \\   /  _____||   ____|   \\   \\  /  \\  /   / /   \\     |   _  \\        /       |\n   |   (----`---|  |----`|  |  |  | |  |  |  | |  |  __  |  |__       \\   \\/    \\/   / /  ^  \\    |  |_)  |      |   (----`\n    \\   \\       |  |     |  |  |  | |  |  |  | |  | |_ | |   __|       \\            / /  /_\\  \\   |      /        \\   \\    \n.----)   |      |  |     |  `--'  | |  `--'  | |  |__| | |  |____       \\    /\\    / /  _____  \\  |  |\\  \\----.----)   |   \n|_______/       |__|      \\______/   \\______/   \\______| |_______|       \\__/  \\__/ /__/     \\__\\ | _| `._____|_______/    \n");
  }
  /**
  *  `"A watched pot, never boils" - andy 08/01/2025`
  */
  andy() {
    console.log(`
			....................................................................................................
			....................................................................................................
			....................................................................................................
			....................................................................................................
			....................................................................................................
			................................................::..................................................
			..............................................@@%#@@................................................
			.............................................#@....%@...............................................
			.............................................=@=..-@%...............................................
			.........................................-@@@@%*..=%@@@@+...........................................
			......................................-%@*..............+@@-........................................
			.....................................%@=...................@@.......................................
			..................................+@@@@@@@@@@@@@@@@@@@@@@@@@@@@#....................................
			..................=*%%*...........@@..........................#@:......*%@@@@@@%-...................
			...............@@#+-:.+@@*......*@@...........................:@@*..=@@+..-:.::-%@+.................
			...............:%@#......=@@*.+@#...............................:#@@@.+@@@*++*@@*-@#................
			..................@@:.......*@@...............................-:..-@@@#.........@%=@+...............
			...................@@.......%@.................................@@...@@..........=@-#%...............
			....................@+.....+@...................................%@:..@*..........@+*@...............
			....................#@.....@%....................................@*..%@.........-@-#@...............
			....................:@+....@#....................................%%..*@:.......:@#-@*...............
			.....................%%....@#....................................@#..%@.......=@#-@*................
			.....................:@*...#@...................................=@-.:@%.....:%@-#@=.................
			......................-@*..-@=..................................@%..:@=...-@@+-@@:..................
			.......................-@%:.%@.....................................:@@.=%@%--%@=....................
			.........................%@#:@@....................................#@@@%..*@@=......................
			..........................:#@%@%..................................#@=.:#@@+.........................
			.............................+@@@:...............................%@%@@%:............................
			...............................-%@*.............................*@@#-...............................
			.................................:%@=.........................-@@...................................
			...................................-@#***#***#****#***#***##*#@=....................................
			....................................https://youtu.be/EPr-JrW-a8o....................................
			....................................-@#***#****#**#***#***##*#@=....................................
			....................................................................................................
			....................................................................................................
			....................................................................................................
			....................................................................................................
		`);
    return 418;
  }
};

// Timezone.ts
var getTimezoneOffset = /* @__PURE__ */ __name((date) => date.getTimezoneOffset(), "getTimezoneOffset");

// index.ts
import_dayjs.default.extend(import_utc.default);
import_dayjs.default.extend(import_timezone.default);
import_dayjs.default.extend(import_advancedFormat.default);
import_dayjs.default.extend(import_weekOfYear.default);
var HumaniDate = class _HumaniDate {
  static {
    __name(this, "HumaniDate");
  }
  _date = (0, import_dayjs.default)();
  _isMDY = false;
  _timezone;
  _location;
  formatting;
  canned;
  validation;
  to;
  /**
  *
  * @param date optional date to initialise with, if nothing provided gets now
  * @param timezone optional timezone, if nothing provided initialises with `Australia/Sydney`, if you want to guess the timezone pass in `guess`
  * @param location optional location, used to display a localised format, default is `AU`
  */
  constructor(date, timezone2 = "Australia/Sydney", location = "AU") {
    this._date = timezone2 === "guess" ? (0, import_dayjs.default)(date) : (0, import_dayjs.default)(date).tz(timezone2);
    this._timezone = timezone2;
    this._isMDY = MDYLocations.includes(location);
    this._location = location;
    this.formatting = new Formatting(this);
    this.canned = new Canned(this);
    this.validation = new Validation(this);
    this.to = new To(this);
  }
  /**
  * Updates the current HumaniDate's timezone
  *
  * @param timezone tz string ie `Australia/Sydney`
  * @param keepLocaleTime keeps the original time
  *
  */
  setTimezone(timezone2, keepLocaleTime = false) {
    console.log(`old: ${this._timezone}, new: ${timezone2}, location: ${this._location}`);
    this._timezone = timezone2;
    this._date = this._date.tz(timezone2, keepLocaleTime);
  }
  /**
  * Returns a **new** HumaniDate object with a timezone applied
  *
  * @param timezone tz string ie `Australia/Sydney`
  * @param keepLocaleTime keeps the original time
  * @returns a **new** humanidate object leaving
  */
  changeTimezone(timezone2, keepLocaleTime = false) {
    return new _HumaniDate(this._date.tz(timezone2, keepLocaleTime), timezone2);
  }
  /**
  *
  * @returns DayJS date object if you have to do some whack shit, but you shouldnt so dont #escape-hatch
  */
  get dayJs() {
    return this._date;
  }
  get isMDY() {
    return this._isMDY;
  }
  /**
  * Determines if the date has passed
  *
  * @param relativeTo optional date to compare relative to; defaults to now
  * @returns boolean value determining if the date has passed
  */
  isPast(relativeTo = /* @__PURE__ */ new Date()) {
    return this._date.isBefore(relativeTo);
  }
  /**
  *
  * `"The whole point of a 'toString' method is to make it pretty when console.log logs it out" - Brenton 22/6/2022`
  *
  * @returns ISO 8601 string
  */
  toString() {
    return this._date.toISOString();
  }
  /**
  * `"cuz its fucking useful" - Brenton 22/6/2022`
  *
  * @returns JS date
  */
  toDate() {
    return this._date.toDate();
  }
  /**
  * If called on the server expect UTC
  *
  * @returns browsers timezone `Australia/Sydney`
  */
  static getBrowserTimezone() {
    return import_dayjs.default.tz.guess();
  }
  /**
  * Exposes formatting static methods
  */
  static formats = {
    datePicker: Formatting.datePicker
  };
  /**
  *  Needs to convert to Date because dayJs does not account for timezone offset
  *
  * @returns boolean determining whether or not the date is in daylight savings time. WARNING: this won't work in the server. Unless we pass the timezone in and set it here manually ;)
  */
  isDST() {
    const jan = this._date.month(0).date(1).toDate();
    const jul = this._date.month(6).date(1).toDate();
    const stdTimezoneOffset = Math.max(getTimezoneOffset(jan), getTimezoneOffset(jul));
    const dstOffset = getTimezoneOffset(this.toDate());
    return dstOffset < stdTimezoneOffset;
  }
  week() {
    return this._date.week();
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  DateFormats,
  HumaniDate,
  TimeFormats,
  TimezoneFormats
});
