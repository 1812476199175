import { fsClient } from "@/services/ImageService";
import { Button, UploadIcon } from "@/ui";
import { ImageUploaderResult } from "@/ui";
import { notification } from "@/ui/antd";
import { PickerFileMetadata, PickerOptions } from "filestack-js";
import { FC, ReactNode } from "react";

const fileStackFromSources = ["local_file_system", "facebook", "instagram", "googledrive", "dropbox"];
const fileStackAcceptFormats = ["image/*"];
const fileStackDefaultImageTransformations = { crop: true, rotate: true };

type HandleImageUploadedProps = {
	filesUploaded: PickerFileMetadata[];
	filesFailed: PickerFileMetadata[];
};

type FileStackUploadButtonProps = {
	aspectRatio?: number;
	disabled?: boolean;
	icon?: ReactNode | null;
	image?: ImageUploaderResult | null;
	onImageChange?: (img: PickerFileMetadata) => void;
};

export const FileStackUploadButton: FC<FileStackUploadButtonProps> = ({
	aspectRatio,
	disabled,
	icon = <UploadIcon small />,
	image,
	onImageChange
}) => {
	const handleImageUploaded = ({ filesUploaded, filesFailed }: HandleImageUploadedProps) => {
		if (filesFailed.length) {
			notification.error({ message: "Image failed to upload" });
			return;
		}

		const uploadedImage = filesUploaded[0];
		onImageChange?.(uploadedImage);
	};

	const handleUploadViaFileStack = () => {
		const options: PickerOptions = {
			accept: fileStackAcceptFormats,
			exposeOriginalFile: true,
			fromSources: fileStackFromSources,
			imageMax: [3200, 3200],
			maxFiles: 1,
			maxSize: 102400000,
			minFiles: 1,
			onUploadDone: handleImageUploaded,
			transformations: fileStackDefaultImageTransformations,
			uploadInBackground: false
		};

		if (aspectRatio) {
			options.transformations!.crop = { aspectRatio, force: true };
		}

		fsClient.picker(options).open();
	};

	const handleEditImage = () => {
		if (!image) return;

		const options: PickerOptions = {
			accept: fileStackAcceptFormats,
			onUploadDone: handleImageUploaded,
			transformations: fileStackDefaultImageTransformations
		};

		if (aspectRatio) {
			options.transformations = {
				...options.transformations,
				crop: { aspectRatio, force: true }
			};
		}

		fsClient.picker(options).crop([image.url]);
	};

	const editMode = !!image?.handle;

	return (
		<Button
			disabled={disabled}
			iconLeft={!editMode && icon}
			onClick={editMode ? handleEditImage : handleUploadViaFileStack}
			variant="tertiary"
		>
			{editMode ? "Edit image" : "Upload image"}
		</Button>
	);
};
