import { CollectionURLParams } from "@/features/collections/editor/CollectionEditorModal";
import { useEvents } from "@/hooks/useEvents";
import { useHostProfiles } from "@/hooks/useHostProfiles";
import { useTags } from "@/hooks/useTags";
import { useAppSelector } from "@/state/hooks";
import {
	Button,
	FormItemRow,
	FormLabel,
	ImageUploader,
	ImageUploaderResult,
	LoadingIcon,
	RadioGroup,
	ResponsiveModal
} from "@/ui";
import { Flex, Form, Input, message, Select } from "@/ui/antd";
import { Collection } from "@hx/console";
import { Location } from "@hx/locations";
import { FormInstance, useWatch } from "antd/lib/form/Form";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";

type EventGroup = Collection["events"]["groups"][0];

const createGroupDefaults = (): Omit<EventGroup, "_id"> => ({
	bannerImage: null,
	hostProfiles: [],
	name: "",
	selectedEvents: [],
	showBy: "selected",
	tagBy: "includesAll",
	tags: [],
	uuid: uuidv4()
});

type EventGroupEditorProps = {
	activeGroupUUID: string | null;
	form: FormInstance<Collection>;
	onClose: () => void;
	open: boolean;
};

export const EventGroupEditor: FC<EventGroupEditorProps> = ({ activeGroupUUID, form, onClose, open }) => {
	const [groupForm] = Form.useForm();
	const user = useAppSelector((state) => state.auth.user);
	const params = useParams<CollectionURLParams>();

	const location: Location = params.location?.toUpperCase() || user.location;

	const groupFormValues = useWatch<EventGroup>([], groupForm);
	const collectionFormValues = useWatch([], form);
	const [hasDiff, setHasDiff] = useState(false);

	const isCreateMode = activeGroupUUID === null;
	const activeGroup =
		open && !isCreateMode ? collectionFormValues?.events.groups.find((group) => group.uuid === activeGroupUUID) : null;

	const events = useEvents(activeGroup?.selectedEvents ?? [], {
		filters: { location }
	});
	const tags = useTags(activeGroup?.tags ?? []);
	const hostProfiles = useHostProfiles(activeGroup?.hostProfiles ?? []);

	useEffect(() => {
		return () => {
			events.reset();
			hostProfiles.reset();
			tags.reset();
		};
	}, []);

	useEffect(() => {
		if (isCreateMode) {
			groupForm.setFieldsValue(createGroupDefaults());
		} else {
			if (activeGroup) {
				groupForm.setFieldsValue(activeGroup);
			}
		}
	}, [activeGroupUUID, open]);

	useEffect(() => {
		const allFormValues = groupForm.getFieldsValue(true);
		const hasDiff = JSON.stringify(allFormValues) !== JSON.stringify(activeGroup);
		setHasDiff(hasDiff);
	}, [activeGroup, groupFormValues]);

	const handleSubmitGroup = async () => {
		const validatedGroupValues = await groupForm.validateFields();

		const isCustom = validatedGroupValues.showBy === "custom";
		const hasTags = validatedGroupValues.tags.length > 0;
		const hasHostProfiles = validatedGroupValues.hostProfiles.length > 0;
		const isCustomInvalid = isCustom && !hasTags && !hasHostProfiles;

		if (isCustomInvalid) {
			message.error("At least one tag or host profile is required.");
			return;
		}

		if (validatedGroupValues) {
			const existingGroups = collectionFormValues.events.groups || [];

			const updatedEvent = {
				events: {
					groups: isCreateMode
						? [...existingGroups, validatedGroupValues]
						: existingGroups.map((group) => {
								if (group.uuid === activeGroupUUID) {
									return validatedGroupValues;
								}
								return group;
						  })
				}
			};

			form.setFieldsValue(updatedEvent);
			groupForm.resetFields();
			onClose();
		}
	};

	const handleOnClose = () => {
		if (hasDiff && !confirm("Close without saving? Changes will be lost.")) {
			return;
		}

		groupForm.setFieldsValue(createGroupDefaults());
		onClose();
	};

	return (
		<ResponsiveModal
			footer={
				<Flex gap="sm">
					<Button onClick={handleOnClose} variant="ghost">
						Cancel
					</Button>
					<Button onClick={handleSubmitGroup} variant="secondary">
						{isCreateMode ? "Create group" : "Done"}
					</Button>
				</Flex>
			}
			onCancel={handleOnClose}
			open={open}
			padding="24px"
			header={isCreateMode ? "Create an event group" : "Edit event group"}
			width="726px"
		>
			<Form autoComplete="off" form={groupForm} layout="vertical">
				<Form.Item hidden name={["uuid"]} />
				<Form.Item
					name={["name"]}
					label={<b>Event group name</b>}
					rules={[
						{ min: 1, required: true, type: "string" },
						{ max: 80, message: "Event group name must be less than 80 characters" },
						{ pattern: /\S/, message: "Event group name must contain at least one character" }
					]}
				>
					<Input
						maxLength={80}
						showCount
						onBlur={(e) => {
							const value = e.target.value;
							if (value.length > 0) {
								groupForm.setFieldsValue({ name: value.trim() });
							}
						}}
					/>
				</Form.Item>
				<FormLabel size={16}>Select events</FormLabel>
				<p>Add events you would like to show within this group.</p>
				{/* SHOW BY */}
				<Form.Item name={["showBy"]}>
					<RadioGroup
						style={{ maxWidth: "280px" }}
						options={[
							{ label: "Selected events", value: "selected" },
							{ label: "Custom", value: "custom" }
						]}
						value={groupFormValues?.showBy}
					/>
				</Form.Item>
				{/* SELECTED EVENTS */}
				<Form.Item
					hidden={groupFormValues?.showBy !== "selected"}
					label={<FormLabel>Add events</FormLabel>}
					name={["selectedEvents"]}
					rules={[
						{
							message: "At least one event is required",
							required: groupFormValues?.showBy === "selected",
							type: "array"
						}
					]}
				>
					<Select
						filterOption={false}
						mode="multiple"
						notFoundContent={events.noContent}
						onChange={events.handleChange}
						onFocus={() => events.search("", null, true)}
						onSearch={events.search}
						options={events.options}
						placeholder="Search your events"
						value={groupFormValues?.selectedEvents}
					/>
				</Form.Item>
				{/* HELPER TEXT FOR CUSTOM MODE */}
				<p hidden={groupFormValues?.showBy !== "custom"}>
					Add events to this group by host profiles, tags, or a combination of both.
				</p>
				{/* HOST PROFILES */}
				<Form.Item
					hidden={groupFormValues?.showBy !== "custom"}
					label={<FormLabel>Include host profile(s)</FormLabel>}
					name={["hostProfiles"]}
				>
					<Select
						mode="multiple"
						filterOption={false}
						notFoundContent={hostProfiles.noContent}
						onChange={hostProfiles.handleChange}
						onFocus={() => hostProfiles.search("", null, true)}
						onSearch={hostProfiles.search}
						options={hostProfiles.options}
						placeholder="Search your host profiles"
						value={groupFormValues?.hostProfiles}
					/>
				</Form.Item>
				{/* TAG BY */}
				<FormItemRow hidden={!user.isTagsEnabled || groupFormValues?.showBy !== "custom"}>
					<FormLabel
						tooltip={`Include all of: Only include events which contain all the specified tags.
					Any of: Include any events that include at least one of the specified tags.`}
					>
						Tags
					</FormLabel>
					<Form.Item name={["tagBy"]} noStyle style={{ display: "flex", flexDirection: "column" }}>
						<Select
							style={{ minWidth: "180px", flexShrink: 1 }}
							value={groupFormValues?.tagBy}
							options={[
								{ value: "includesAll", label: "Includes all of" },
								{ value: "includesAny", label: "Any of" }
							]}
						/>
					</Form.Item>
				</FormItemRow>
				{/* INCLUDE TAGS */}
				<Form.Item hidden={!user.isTagsEnabled || groupFormValues?.showBy !== "custom"} name={["tags"]}>
					<Select
						filterOption={false}
						mode="tags"
						notFoundContent={tags.noContent}
						onChange={tags.handleChange}
						onFocus={() => tags.search("", null, true)}
						onSearch={tags.search}
						placeholder="Search or create tags"
						style={{ flexGrow: 1 }}
						value={tags.selectedTags.map((tag) => tag.value)}
						options={tags.options.map((tag) => {
							const resolvedLabel = tag.label === tag.value ? <LoadingIcon /> : tag.label;
							return { value: tag.value, label: resolvedLabel };
						})}
					/>
				</Form.Item>
				{/* IMAGE UPLOAD */}
				<Form.Item label={<FormLabel>Upload banner image</FormLabel>} name={["bannerImage"]}>
					<ImageUploader canva defaultImage={groupFormValues?.bannerImage as ImageUploaderResult} small />
				</Form.Item>
			</Form>
		</ResponsiveModal>
	);
};
