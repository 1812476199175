import { getConfig } from "@/config";
import TokenService from "@/services/TokenService";
import UsersService from "@/services/UsersService";
import { useAppDispatch } from "@/state/hooks";
import { trpc } from "@/trpc";
import ax from "axios";
import {
	User as FirebaseUser,
	getAdditionalUserInfo,
	getAuth,
	GoogleAuthProvider,
	linkWithPopup,
	signInWithCustomToken,
	signInWithPopup,
	signOut,
	unlink,
	UserCredential
} from "firebase/auth";

export type SignInWithUtsSSOParams = {
	code: string;
	redirect_uri: string;
	nonce: string;
	state: string | null;
};

export type SignInWithUtsSSOResponse = {
	isNewUser: boolean;
	user: FirebaseUser;
};

type LinkWithPopupResult = UserCredential & {
	_tokenResponse: {
		email: string;
	};
};

export const useSingleSignOn = () => {
	const { refetch: fetchUtsSsoConfig } = trpc.authorization.utsSsoConfig.useQuery(undefined, { enabled: false });
	const dispatch = useAppDispatch();
	const googleProvider = new GoogleAuthProvider();
	googleProvider.setCustomParameters({
		prompt: "select_account"
	});

	const launchMicrosoftSignIn = async (email: string): Promise<void> => {
		const { data: utsSsoConfigData } = await fetchUtsSsoConfig();

		if (!utsSsoConfigData) {
			throw new Error("Failed to get single sign-on configuration");
		}

		const { tenant, client_id } = utsSsoConfigData ?? {};

		const consoleUrl = getConfig("CONSOLE_URL");
		const redirectUrl = `${consoleUrl}/sso/microsoft`;

		const params = new URLSearchParams({
			client_id,
			response_type: "code",
			redirect_uri: redirectUrl,
			response_mode: "query",
			state: email,
			login_hint: email
		});
		const loginUrl = new URL(`https://login.microsoftonline.com/${tenant}/oauth2/authorize`);
		loginUrl.search = params.toString();
		window.location.href = loginUrl.toString();
	};

	const signInWithUtsSSO = async (params: SignInWithUtsSSOParams): Promise<SignInWithUtsSSOResponse> => {
		const response = await ax.post("/public-api/microsoft/authorise", params);
		const userData = response.data;
		const firebaseResponse = await signInWithCustomToken(getAuth(), userData.token);
		TokenService.persistToken(userData.token, dispatch);

		const isNew = userData.matchedUserIds.indexOf(firebaseResponse.user.uid) === -1;

		if (isNew) {
			const config = { ...userData.config };
			delete userData.config;
			delete userData.matchedUserIds;
			const userDetails = {
				...userData,
				...config.userSetting,
				id: firebaseResponse.user.uid,
				role: "user",
				location: "AU"
			};

			delete userDetails.token;

			// create the user
			await UsersService.create(userDetails.uid, userDetails);
		}

		return { isNewUser: userData.isNew, user: firebaseResponse.user };
	};

	const linkGoogleSSO = async () => {
		const user = getAuth().currentUser;
		if (!user) {
			throw new Error("Unable to link Google SSO. No user logged in.");
		}

		const currentUserEmail = user.email;
		const linkResult = await linkWithPopup(user, googleProvider);
		const linkedEmailAddress = (linkResult as LinkWithPopupResult)._tokenResponse.email;

		if (currentUserEmail !== linkedEmailAddress) {
			await unlinkGoogleSSO();
			throw new Error("You must link your Google account to the email address you're logged in with.");
		}

		const credential = GoogleAuthProvider.credentialFromResult(linkResult);
		return { credential, user: linkResult.user };
	};

	const unlinkGoogleSSO = async () => {
		const user = getAuth().currentUser;
		if (!user) {
			throw new Error("Unable to unlink Google SSO. No user logged in.");
		}

		return await unlink(user, googleProvider.providerId);
	};

	const signInWithGoogleSSO = async ({ expectedEmailAddress }: { expectedEmailAddress: string }) => {
		const response = await signInWithPopup(getAuth(), googleProvider);

		const additionalUserInfo = getAdditionalUserInfo(response);
		if (!additionalUserInfo) {
			throw new Error("Failed to sign in with Google SSO");
		}

		const { isNewUser } = additionalUserInfo;
		const profile: Record<string, unknown> | null = additionalUserInfo.profile;

		if (!profile) {
			throw new Error("Failed to sign in with Google SSO");
		}

		const { email } = profile;
		if (email !== expectedEmailAddress) {
			// if the user is brand new, we delete the firebase account.
			if (isNewUser) {
				getAuth().currentUser?.delete();
			}

			await signOut(getAuth());
			throw new Error("You must sign in with the correct account");
		}

		if (isNewUser) {
			// this case should never occur, since the google SSO popup will only pop up for existing users. And if they are new, they will be deleted above.
			// this case will become handy once we allow users to sign up via SSO.
			await signOut(getAuth());
			throw new Error("New users are not allowed to sign in with Google SSO");
		}

		return response.user;
	};

	return {
		launchMicrosoftSignIn,
		linkGoogleSSO,
		signInWithGoogleSSO,
		signInWithUtsSSO,
		unlinkGoogleSSO
	};
};
