import { Table } from "@/ui/antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ContentBlock } from "../components/AppBlocks";
import { CheckboxField } from "../components/Fields";
import FormBar from "../components/FormBar";
import LoadErrorView from "../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import P from "../components/text/P";
import { getUser, updateUserAdminPermissions } from "../state/actions/userActions";

const permissions = [
	{
		permission: "Edit permissions",
		key: "editPermissions",
		description: "Allow this user to edit admin permissions for other admins"
	},
	{
		permission: "Payouts",
		key: "payouts",
		description: "Allow this user create, edit and reverse payouts"
	},
	{
		permission: "Tax info",
		key: "taxInfo",
		description: "Allow this user to view sensitive tax information"
	},
	{
		permission: "Self-serve payments",
		key: "selfServicePayouts",
		description: "Allow this admin to enable self-serve payments for users"
	}
];

const columns = [
	{
		title: "Permission",
		dataIndex: "permission",
		key: "permission",
		render: (value) => {
			return <P>{value}</P>;
		}
	},
	{
		title: "Description",
		dataIndex: "description",
		key: "description",
		render: (value) => {
			return <P>{value}</P>;
		}
	},
	{
		title: "Active",
		dataIndex: "key",
		key: "key",
		render: (value) => {
			return <Field id={value} name={value} component={CheckboxField} />;
		}
	}
];

let AdminPermissions = ({ currentUser, userId, getUser, handleSubmit, updateUserAdminPermissions }) => {
	useEffect(() => {
		loadUser();
	}, []);
	const loadUser = () => {
		getUser(userId);
	};
	const save = (values) => {
		const valuesToSave = Object.keys(values).reduce((acc, key) => {
			if (values[key]) {
				return [...acc, key];
			}
			return acc;
		}, []);
		updateUserAdminPermissions(currentUser.user.id, valuesToSave, currentUser.user.location);
	};
	return (
		<LoadErrorView loading={currentUser.loading} error={currentUser.error} retryAction={() => loadUser()}>
			<form onSubmit={handleSubmit(save)}>
				<ContentBlock>
					{currentUser?.user?.role === "admin" ? (
						<>
							<P>
								View and change admin permissions. You are only able to edit admin permissions if your account has the
								"Edit permissions" permission.
							</P>
							<Table columns={columns} dataSource={permissions} pagination={false} />
						</>
					) : (
						<P>User is not an admin, therefore has no admin permissions</P>
					)}
				</ContentBlock>
				{currentUser?.user?.role === "admin" ? (
					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							ariaLabel="Save"
							size="large"
						>
							Update
						</LegacyButton>
					</FormBar>
				) : null}
			</form>
		</LoadErrorView>
	);
};

const formName = "AdminPermissions";

AdminPermissions = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(AdminPermissions);

export default connect(
	(state) => {
		const currentUser = state.currentUser;
		const initialValues =
			currentUser?.user?.adminPermissions?.reduce((acc, key) => {
				acc[key] = true;
				return acc;
			}, {}) || {};

		return {
			initialValues,
			currentUser
		};
	},
	{ getUser, updateUserAdminPermissions }
)(AdminPermissions);
