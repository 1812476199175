import { Button, DeleteIcon, DragHandle, EditIcon, ImageUploaderResult } from "@/ui";
import { Flex, Form, Typography } from "@/ui/antd";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";

const StyledButton = styled(Button)`
	min-height: 30px;
	min-width: 30px;
	padding: 8px;
`;

const StyledTitleContainer = styled(Flex)`
	cursor: pointer;
	padding: var(--spacing-md);
	padding-right: 0;
`;

const StyledImage = styled.img`
	border-radius: 4px;
	overflow: hidden;
	object-fit: cover;
	margin-right: 8px;
`;

const StyledDraggable = styled.div<{
	isDragging: boolean;
	transform: string;
	transition: string;
}>`
	${({ isDragging, transform, transition }) => css`
		background-color: ${isDragging ? "var(--surface3)" : "var(--surface2)"};
		border-radius: var(--rounded-sm);
		display: flex;
		transform: ${transform};
		transition: ${transition};
		z-index: ${isDragging ? 1 : ""};
		width: 100%;
	`}
`;

type EventGroupItemRowProps = {
	id: string;
	image: ImageUploaderResult | null;
	onDelete: () => void;
	onEdit: () => void;
	showDragHandle?: boolean;
	title: string;
};

export const EventGroupItemRow: FC<EventGroupItemRowProps> = ({
	id,
	image,
	onDelete,
	onEdit,
	showDragHandle,
	title
}) => {
	const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id });

	return (
		<StyledDraggable
			isDragging={isDragging}
			ref={setNodeRef}
			transform={CSS.Transform.toString(transform) || ""}
			transition={transition || ""}
		>
			<Form.Item key={id} style={{ marginBottom: 0, width: "100%" }}>
				<Flex justify="space-between" wide>
					<StyledTitleContainer gap="sm" wide {...attributes} {...listeners}>
						{showDragHandle && <DragHandle small fill="var(--pressed)" />}
						<Flex align="center" style={{ flexGrow: 1 }}>
							{image && <StyledImage alt={image.alt} height={30} src={image.url} width={50} />}
							<Typography.Text style={{ fontWeight: 400 }}>{title}</Typography.Text>
						</Flex>
					</StyledTitleContainer>
					<Flex align="center" gap="sm" padding="md">
						<StyledButton iconOnly onClick={onEdit} variant="text">
							<EditIcon small />
						</StyledButton>
						<StyledButton iconOnly onClick={onDelete} variant="text">
							<DeleteIcon small />
						</StyledButton>
					</Flex>
				</Flex>
			</Form.Item>
		</StyledDraggable>
	);
};
