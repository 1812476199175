import { Input, InputProps } from "@/ui/antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { FC, useState } from "react";

const Suffix = styled.div`
	cursor: pointer;
`;

export const Password: FC<InputProps> = ({ children, ...props }) => {
	const [showValue, setShowValue] = useState(false);

	return (
		<Input
			type={showValue ? "text" : "password"}
			suffix={
				<Suffix onClick={() => setShowValue(!showValue)}>
					{showValue ? <EyeInvisibleOutlined /> : <EyeOutlined />}
				</Suffix>
			}
			{...props}
		>
			{children}
		</Input>
	);
};
