import { AccordionPage } from "@/shaksPlace/AccordionPage";
import { ButtonsPage } from "@/shaksPlace/ButtonsPage";
import { CardPage } from "@/shaksPlace/CardPage";
import { CodeBlockPage } from "@/shaksPlace/CodeBlockPage";
import { CopyButtonPage } from "@/shaksPlace/CopyButtonPage";
import { CopyFieldPage } from "@/shaksPlace/CopyFieldPage";
import { DateTimePickerPage } from "@/shaksPlace/DateTimePickerPage";
import { DropdownPage } from "@/shaksPlace/DropdownPage";
import { FormLabelPage } from "@/shaksPlace/FormLabelPage";
import { GlobalStylesPage } from "@/shaksPlace/GlobalStylesPage";
import { GridPage } from "@/shaksPlace/GridPage";
import { IconNavigationMenuPage } from "@/shaksPlace/IconNavigationMenuPage";
import { IconsPage } from "@/shaksPlace/IconsPage";
import { ImageUploaderPage } from "@/shaksPlace/ImageUploaderPage";
import { InfoTooltipPage } from "@/shaksPlace/InfoTooltipPage";
import { LottiePlayerPage } from "@/shaksPlace/LottiePlayerPage";
import { PreviewMockSitePage } from "@/shaksPlace/PreviewMockSitePage";
import { RadioGroupPage } from "@/shaksPlace/RadioGroupPage";
import { ReduxDateTimePickerPage } from "@/shaksPlace/ReduxDateTimePickerPage";
import { ResponsiveModalPage } from "@/shaksPlace/ResponsiveModalPage";
import { ResponsivePage } from "@/shaksPlace/ResponsivePage";
import { SpacerPage } from "@/shaksPlace/SpacerPage";
import { SvgPage } from "@/shaksPlace/SvgPage";
import { TagsPage } from "@/shaksPlace/TagsPage";
import { Button, DropdownItem } from "@/ui";
import styled from "@emotion/styled";
import { FC } from "react";
import { Route, Routes, useLocation } from "react-router";
import { Link } from "react-router-dom";

const Layout = styled.div`
	background-color: #ffffff;
	display: flex;
	height: 100%;
	overflow: hidden;
	width: 100%;
`;

const SideNav = styled.div`
	background-color: rgb(0, 0, 0, 0.02);
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: 100%;
	overflow: auto;
	padding: 20px 8px;
	width: 200px;
`;

const Contents = styled.div<{
	center: boolean;
	darkenBackground?: boolean;
}>`
	align-items: ${(props) => (props.center ? "center" : "flex-start")};
	background-color: ${(props) => (props.darkenBackground ? "rgb(0, 0, 0, 0.05)" : "transparent")};
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	justify-content: ${(props) => (props.center ? "center" : "flex-start")};
	overflow: auto;
	padding: ${(props) => (props.center ? "0" : "24px 5%")};
	position: relative;
`;

const H1 = styled.h1`
	-webkit-background-clip: text;
	animation: rotate 20s linear infinite;
	background-clip: text;
	background-size: 600%;
	color: transparent;
	font-size: 32px;
	font-weight: 900;
	position: relative;
	background-image: linear-gradient(
		90deg,
		#ff0000,
		#ff7700,
		#ffdd00,
		#00ff00,
		#00c6ab,
		#0000ff,
		#7700ff,
		#dd00ff,
		#ff0000,
		#ff7700
	);

	@keyframes rotate {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`;

const Emoji = styled.span<{
	left?: string;
	right?: string;
	top?: string;
	bottom?: string;
	rotate?: string;
}>`
	bottom: ${(props) => props.bottom};
	font-size: 128px;
	left: ${(props) => props.left};
	position: absolute;
	right: ${(props) => props.right};
	top: ${(props) => props.top};
	transform: rotate(${(props) => props.rotate});
`;

export const ShaksSpecialPlaceDashboard: FC = () => {
	const location = useLocation();
	const pages = [
		{ component: <AccordionPage />, name: "Accordion", slug: "accordion" },
		{ component: <ButtonsPage />, name: "Button", slug: "button" },
		{ component: <CardPage />, name: "Card", slug: "card" },
		{ component: <CodeBlockPage />, name: "CodeBlock", slug: "code-block" },
		{ component: <CopyButtonPage />, name: "CopyButton", slug: "copy-button" },
		{ component: <CopyFieldPage />, name: "CopyField", slug: "copy-field" },
		{ component: <DateTimePickerPage />, name: "DateTimePicker", slug: "datetimepicker" },
		{ component: <DropdownPage />, name: "Dropdown", slug: "dropdown" },
		{ component: <FormLabelPage />, name: "FormLabel", slug: "form-label" },
		{ component: <GlobalStylesPage />, name: "GlobalStyles", slug: "global-styles" },
		{ component: <GridPage />, name: "Grid", slug: "grid" },
		{ component: <IconNavigationMenuPage />, name: "IconNavigationMenu", slug: "icon-nav-menu" },
		{ component: <IconsPage />, name: "Icons", slug: "icons" },
		{ component: <ImageUploaderPage />, name: "ImageUploader", slug: "image-uploader" },
		{ component: <InfoTooltipPage />, name: "InfoTooltip", slug: "info-tooltip" },
		{ component: <LottiePlayerPage />, name: "LottiePlayer", slug: "lottie-player" },
		{ component: <PreviewMockSitePage />, name: "PreviewMockSite", slug: "preview-mock-site" },
		{ component: <RadioGroupPage />, name: "RadioGroup", slug: "radio-group" },
		{ component: <ReduxDateTimePickerPage />, name: "ReduxDateTimePicker", slug: "redux-date-time-picker" },
		{ component: <ResponsiveModalPage />, name: "ResponsiveModal", slug: "responsive-model" },
		{ component: <ResponsivePage />, name: "Responsive", slug: "responsive" },
		{ component: <SpacerPage />, name: "Spacer", slug: "spacer" },
		{ component: <SvgPage />, name: "Svg", slug: "svg" },
		{ component: <TagsPage />, name: "Tag", slug: "tag" }
	];

	const home = (
		<Contents center={true}>
			<H1>Welcome to Shak's Special Place</H1>
			<Emoji left="10%" top="5%" rotate="45deg">
				🦋
			</Emoji>
			<Emoji right="-24px" top="0" rotate="-45deg">
				🦄
			</Emoji>
			<Emoji left="10%" bottom="-48px">
				🌱
			</Emoji>
			<Emoji right="-4px" bottom="-48px">
				🌈
			</Emoji>
		</Contents>
	);

	const pathBase = "console/shaks-place";
	const componentsWithDarkBg = ["button", "card"];

	return (
		<Layout>
			<SideNav>
				<Link to={`/${pathBase}`}>
					<Button fullWidth variant="text">
						Shak's Place
					</Button>
				</Link>
				<hr />
				{pages
					.sort((page1, page2) => page1.name.localeCompare(page2.name))
					.map(({ name, slug }, i) => {
						const componentPath = `/${pathBase}/${slug.toLowerCase()}`;
						const routeMatch = location.pathname === componentPath;
						return (
							<Link key={i} to={`/${pathBase}/${slug.toLowerCase()}`}>
								<DropdownItem fullWidth variant={routeMatch ? "secondary" : "text"}>
									{name}
								</DropdownItem>
							</Link>
						);
					})}
			</SideNav>
			<Routes>
				<Route path="" element={home} />
				{pages.map(({ component, name, slug }, i) => (
					<Route
						key={i}
						path={slug.toLowerCase()}
						element={
							<Contents
								center={false}
								darkenBackground={componentsWithDarkBg
									.map((path) => `/${pathBase}/${path}`)
									.includes(location.pathname)}
							>
								<H1>{`<${name} />`}</H1>
								{component}
							</Contents>
						}
					/>
				))}
				<Route path="*" element={home} />
			</Routes>
		</Layout>
	);
};
