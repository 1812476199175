import { Form, Input } from "@/ui/antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import styled from "@emotion/styled";

const PasswordList = styled.ol`
	list-style: none;
	margin-left: 0;
	padding-left: 0;
`;

const PasswordItem = styled.li`
	margin-left: 0;
	padding-left: 0;
`;

interface Rule {
	valid: boolean;
	message: string;
	min?: number;
	pattern?: RegExp;
}

interface PasswordState {
	rules: Rule[];
	state: null | boolean;
}

const IconDisplay = ({ valid }: { valid: boolean }) => {
	return valid ? (
		<CheckOutlined style={{ marginRight: 6, color: "#007349" }} />
	) : (
		<CloseOutlined style={{ marginRight: 6, color: "#ff4d4f" }} />
	);
};

export const PasswordField: FC<{ label: string; fieldName: string }> = ({ label, fieldName }) => {
	const [passwordState, updatePasswordState] = useState({
		rules: [
			{
				valid: false,
				message: "At least 8 characters long",
				min: 8
			},
			{
				valid: false,
				pattern: /[A-Z]/,
				message: "At least 1 uppercase letter"
			},
			{
				valid: false,
				pattern: /[a-z]/,
				message: "At least 1 lowercase letter"
			},
			{
				valid: false,
				pattern: /[0-9]/,
				message: "At least 1 number"
			},
			{
				valid: false,
				pattern: /[^A-Za-z0-9]/,
				message: "At least 1 special character"
			}
		],
		state: null
	} as PasswordState);

	const passordVailator = (_: any, value: string) => {
		let validField: boolean = true;
		const updated = passwordState.rules.map((rule) => {
			if (rule.min) {
				rule.valid = value.length >= rule.min;
			} else if (rule.pattern) {
				rule.valid = rule.pattern.test(value);
			}
			validField = validField && rule.valid;
			return rule;
		});
		updatePasswordState({ rules: updated, state: validField });

		if (validField) {
			return Promise.resolve();
		} else {
			return Promise.reject(new Error("Password does not meet the requirements"));
		}
	};

	return (
		<>
			<Form.Item
				name={fieldName}
				label={<b>{label}</b>}
				required
				rules={[
					{
						validator: passordVailator
					}
				]}
			>
				<Input.Password placeholder="Password" />
			</Form.Item>
			<PasswordList>
				{passwordState.rules.map((rule) => (
					<PasswordItem>
						<IconDisplay valid={rule.valid} />
						{rule.message}
					</PasswordItem>
				))}
			</PasswordList>
		</>
	);
};
