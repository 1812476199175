import {
	CollectionsConfigPanel,
	CollectionsConfigPanelKey,
	configMenuItems
} from "@/features/collections/configPanel/CollectionsConfigPanel";
import { CollectionEditorModalHeader } from "@/features/collections/editor/CollectionEditorModalHeader";
import { ReameCollectionModal } from "@/features/collections/editor/ReameCollectionModal";
import { ShareCollectionModal } from "@/features/collections/editor/ShareCollectionModal";
import { PreviewPanel } from "@/features/collections/preview/PreviewPanel";
import { useCollectionURL } from "@/hooks/useCollectionURL";
import { trpc } from "@/trpc";
import { IconNavigationMenu, ResponsiveModal, SimpleQuestionIcon } from "@/ui";
import { Form, message } from "@/ui/antd";
import { HelpIds, OpenHelp } from "@/utils/Help";
import { deepEqual } from "@/utils/Object";
import styled from "@emotion/styled";
import { Collection } from "@hx/console";
import { Location } from "@hx/locations";
import { useWatch } from "antd/lib/form/Form";
import { FC, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

export type CollectionURLParams = {
	"*": string;
	collectionId: string;
	location: Location;
	tab: CollectionsConfigPanelKey;
};

type FormError = {
	errorFields: {
		name: string;
		errors: ReactNode[];
	}[];
};

const ContentArea = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height: 100%;
	width: 100%;
`;

const FlexContainer = styled.div`
	column-gap: 48px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	overflow-y: scroll;
	width: 100%;
`;

export const CollectionEditorModal: FC = () => {
	const params = useParams<CollectionURLParams>();
	const { collectionId, tab } = params;
	const [selectedPanel, setSelectedPanel] = useState<CollectionsConfigPanelKey>(tab ?? "events");
	const getCollectionProcedure = trpc.collections.getCollection.useQuery(
		{ collectionId: collectionId ?? "", location: params.location?.toUpperCase() as Location },
		{ enabled: !!collectionId, networkMode: "always" }
	);
	const activeCollection = getCollectionProcedure.data?.collection;
	const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
	const [editCollectionForm] = Form.useForm<Collection>();
	const [hasDiff, setHasDiff] = useState(false);
	const formValues = useWatch([], editCollectionForm);
	const navigate = useNavigate();
	const [isShareCollectionModalOpen, setIsShareCollectionModalOpen] = useState(false);
	const collectionURL = useCollectionURL({
		collectionSlug: activeCollection?.info.slug ?? "",
		isPreview: !activeCollection?.published
	});

	const getUserCollectionsSummaryProcedure = trpc.collections.getUserCollectionsSummary.useQuery(undefined, {
		networkMode: "always"
	});
	const updateCollectionProcedure = trpc.collections.updateCollection.useMutation({
		networkMode: "always"
	});

	useEffect(() => {
		if (!isRenameModalOpen && !getCollectionProcedure.isFetching) {
			setIsRenameModalOpen(!activeCollection?.name);
		}
	}, [activeCollection]);

	useEffect(() => {
		setSelectedPanel(tab ?? "events");

		if (!Object.keys(configMenuItems).includes(tab ?? "")) {
			navigate(`../${collectionId}/events`);
		}
	}, [tab]);

	useEffect(() => {
		if (activeCollection) {
			editCollectionForm.setFieldsValue(activeCollection);
		}
	}, [activeCollection]);

	useEffect(() => {
		const allFormValues = editCollectionForm.getFieldsValue(true);
		const hasDiff = !deepEqual(allFormValues, activeCollection);

		setHasDiff(hasDiff);
	}, [activeCollection, formValues]);

	const isPublishDisabled = updateCollectionProcedure.isLoading || formValues?.name?.length < 1;

	const handleSave = async (): Promise<Boolean> => {
		const isFormValid = await editCollectionForm.validateFields({ validateOnly: true }).catch((err: FormError) => {
			const errors = err.errorFields.flatMap((error) => error.errors);
			message.error(errors);
		});

		if (isFormValid) {
			const formValues: Collection = editCollectionForm.getFieldsValue(true);
			return await updateCollectionProcedure
				.mutateAsync(formValues)
				.then(() => {
					getUserCollectionsSummaryProcedure.refetch();
					getCollectionProcedure.refetch();
					message.success("Collection saved");
					return true;
				})
				.catch((err) => {
					message.error(err);
					return false;
				});
		}
		return false;
	};

	const handleTogglePublishClicked = async () => {
		const isPublishing = !activeCollection?.published;

		editCollectionForm.setFieldValue("published", !activeCollection?.published);
		const saveSuccess = await handleSave();

		if (saveSuccess && isPublishing) {
			setIsShareCollectionModalOpen(true);
		}
	};

	const handleSubmitNameChange = (name: string) => {
		editCollectionForm.setFieldValue("name", name);
		setIsRenameModalOpen(false);
	};

	const handleChangeTab = (key: string) => {
		setSelectedPanel(key as CollectionsConfigPanelKey);
		navigate(`../${collectionId}/${key}`);
	};

	const handleOnClose = () => {
		if (hasDiff && !confirm("Close without saving? Changes will be lost.")) {
			return;
		}

		navigate("../..");
	};

	const hasCollectionReady =
		!!collectionId && !!activeCollection?._id && !getCollectionProcedure.error && !getCollectionProcedure.isLoading;

	return (
		<>
			<ResponsiveModal
				footer={false}
				fullscreen
				onCancel={handleOnClose}
				open={hasCollectionReady}
				padding={false}
				primaryButtonAction={handleSave}
				primaryButtonText="Save"
				header={
					<CollectionEditorModalHeader
						activeCollectionName={editCollectionForm?.getFieldValue("name") ?? null}
						collectionURL={collectionURL}
						isSaving={updateCollectionProcedure.isLoading}
						isSaveDisabled={!hasDiff || updateCollectionProcedure.isLoading}
						isPublishDisabled={isPublishDisabled}
						isPublished={activeCollection?.published}
						onClose={handleOnClose}
						onPublish={handleTogglePublishClicked}
						onRenameCollection={() => setIsRenameModalOpen(true)}
						onSave={handleSave}
					/>
				}
			>
				{activeCollection ? (
					<ContentArea>
						<IconNavigationMenu
							items={Object.values(configMenuItems)}
							onSelect={handleChangeTab}
							selected={selectedPanel}
							endItems={[
								{
									icon: <SimpleQuestionIcon size="20px" />,
									key: "help",
									label: "Help",
									onClick: () => OpenHelp(HelpIds.collections)
								}
							]}
						/>
						<FlexContainer>
							{!!activeCollection && (
								<CollectionsConfigPanel form={editCollectionForm} selectedPanelKey={selectedPanel} />
							)}
							<PreviewPanel
								activeCollection={activeCollection}
								hasUnsavedChanges={hasDiff}
								selectedPanel={selectedPanel}
							/>
						</FlexContainer>
					</ContentArea>
				) : null}
			</ResponsiveModal>

			<ShareCollectionModal
				collectionURL={collectionURL}
				open={isShareCollectionModalOpen}
				onCloseClicked={() => setIsShareCollectionModalOpen(false)}
				showYourCollectionIsLive
			/>

			<ReameCollectionModal
				defaultName={editCollectionForm?.getFieldValue("name")}
				onClose={() => setIsRenameModalOpen(false)}
				onSubmit={handleSubmitNameChange}
				open={isRenameModalOpen && hasCollectionReady}
			/>
		</>
	);
};
