import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ContentBlock, LoadingSpinner } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import PageHeading from "../../components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getMassPayout, getMassPayoutCSV } from "../../state/actions/massPayoutActions";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { HumaniDate } from "@hx/dates";
import { currency } from "@/utils/Format";

class MassPayoutDetails extends Component {
	componentDidMount() {
		this.load();
	}

	load = () => {
		const { getMassPayout, params } = this.props;
		const massPaymentId = params?.massPaymentId;
		getMassPayout(massPaymentId);
	};

	download = () => {
		const { getMassPayoutCSV, params } = this.props;
		const massPaymentId = params?.massPaymentId;
		getMassPayoutCSV(massPaymentId);
	};

	render() {
		const { massPayouts } = this.props;
		const csvLoading = massPayouts && massPayouts.payoutCSV && massPayouts.payoutCSV.loading;
		let columns = [
			{
				title: "#",
				key: "index",
				render: (value, record, index) => index + 1
			},
			{
				title: "Event",
				dataIndex: "eventName",
				key: "eventName",
				render: (value, record) => <Link to={`/console/my-events/${record.eventId}/payments/payouts`}>{value}</Link>
			},

			{
				title: "Logged at",
				dataIndex: "date",
				key: "date",
				render: (value) => new HumaniDate(value).formatting.dateTime()
			},
			{
				title: "Logged by",
				dataIndex: ["payments", "userName"],
				key: "userName",
				render: (value, record) => {
					return record.payments.processedType !== "auto" && record.payments.processedType !== "auto-self-serve" ? (
						<Link to={`/console/admin/users/${record.payments.userId}`}>{value}</Link>
					) : (
						value
					);
				}
			},
			{
				title: "Type",
				dataIndex: ["payments", "processedType"],
				key: "processedType",
				render: (value) => {
					switch (value) {
						case "manual":
							return "Manual";
						case "auto":
							return "Auto";
						case "self-serve":
							return "Self Serve";
						case "auto-self-serve":
							return "Self Serve(Auto)";
						default:
							return value;
					}
				}
			},
			{
				title: "Payment reference",
				dataIndex: ["payments", "payoutReference"],
				key: "payoutReference"
			},
			{
				title: "Bank details",
				dataIndex: "payment",
				key: "account",
				render: (value, payment) => {
					return payment.account ? (
						<div>
							{payment.account.name} {payment.account.bsb} {payment.account.number}
						</div>
					) : (
						"No set"
					);
				}
			},
			{
				title: "Bank details updated At",
				dataIndex: "payment",
				key: "bankUpdatedAt",
				render: (value, payment) => {
					return payment.account ? (
						<div>
							Updated {payment.daySinceBankUpdate} days ago
							<br />
							{new HumaniDate(payment.account.updatedAt).formatting.dateTime()}
						</div>
					) : (
						"No set"
					);
				}
			},
			{
				title: "Previous bank account payouts",
				dataIndex: "pastPayments",
				key: "pastPayments"
			},
			{
				title: "Amount",
				dataIndex: "amount",
				key: "amount",
				render: (value) => currency(value)
			}
		];
		return (
			<>
				<PageHeading title="Mass Payout">
					<LegacyButton onClick={csvLoading ? null : this.download} ariaLabel="Export csv" type="action">
						{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
					</LegacyButton>
				</PageHeading>
				<LoadErrorView
					loading={massPayouts.payout.loading}
					error={massPayouts.payout.error}
					retryAction={() => this.load()}
				>
					<ContentBlock>
						<div style={{ minWidth: "100%", overflowX: "auto" }}>
							{!massPayouts.payout.loading && massPayouts.payout.data ? (
								<Table columns={columns} dataSource={massPayouts.payout.data} rowKey="_id" pagination={false} />
							) : null}
						</div>
					</ContentBlock>
				</LoadErrorView>
			</>
		);
	}
}

export default connect(
	(state) => ({
		massPayouts: state.massPayouts
	}),
	{
		getMassPayout,
		getMassPayoutCSV
	}
)(withRouterHooks(MassPayoutDetails));
