import LoadErrorView from "@/components/LoadErrorView";
import { H2, H4, P } from "@/components/text/Text";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useReauthentication } from "@/hooks/useReauthentication";
import { Button } from "@/ui";
import { Flex, Tooltip } from "@/ui/antd";
import { ConfirmationModal } from "@/ui/modals/ConfirmationModal";
import styled from "@emotion/styled";
import { useState } from "react";

const Block = styled.div`
	display: block;
`;

enum PostConfirmationAction {
	REMOVE_GOOGLE_SSO = "remove-google-sso"
}

export const SignInMethods = () => {
	const { hasSignInMethod, isLoading, isAdmin } = useCurrentUser();
	const { reauthenticate } = useReauthentication();
	const { googleSSOLink, googleSSOUnlink } = useAuth();
	const [isGoogleLoading, setIsGoogleLoading] = useState(false);

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [postConfirmationAction, setPostConfirmationAction] = useState<PostConfirmationAction>();

	const handleLinkGoogleSSO = async () => {
		setIsGoogleLoading(true);
		try {
			await reauthenticate();
			await googleSSOLink();
			setIsGoogleLoading(false);
		} catch (error) {
			setIsGoogleLoading(false);
		}
	};

	const handleUnlinkGoogleSSO = async () => {
		setIsGoogleLoading(true);
		try {
			await reauthenticate();
			setPostConfirmationAction(PostConfirmationAction.REMOVE_GOOGLE_SSO);
			setShowConfirmationModal(true);
		} catch {
			setIsGoogleLoading(false);
			setShowConfirmationModal(false);
		}
	};
	const hasPassword = hasSignInMethod("password");
	const hasSSO = hasSignInMethod("google.com");

	const isAdminWithSSO = isAdmin && hasSSO;

	return (
		<LoadErrorView loading={isLoading}>
			<H2>Sign-in methods</H2>
			<Flex gap="xl" vertical>
				<Block>
					<H4>Email</H4>
					{hasPassword ? (
						<Button variant="tertiary" disabled>
							Remove
						</Button>
					) : (
						<Button variant="tertiary" disabled>
							Add
						</Button>
					)}
				</Block>

				<Block>
					<H4>Google</H4>

					{hasSSO ? (
						<Tooltip
							title="Removing SSO is disabled internally. Please speak with the tech team if you require it to be removed."
							hidden={!isAdmin || !isAdminWithSSO}
						>
							<Button
								variant="tertiary"
								onClick={async (e) => {
									e.currentTarget.blur();
									await handleUnlinkGoogleSSO();
								}}
								isLoading={isGoogleLoading || isLoading}
								disabled={isGoogleLoading || isLoading || isAdminWithSSO}
							>
								Remove
							</Button>
						</Tooltip>
					) : (
						<Button
							variant="tertiary"
							onClick={async (e) => {
								e.currentTarget.blur();
								await handleLinkGoogleSSO();
							}}
							isLoading={isGoogleLoading || isLoading}
							disabled={isGoogleLoading || isLoading}
						>
							Add
						</Button>
					)}
				</Block>
			</Flex>

			<ConfirmationModal
				open={showConfirmationModal}
				header={<H4 style={{ marginBottom: 0 }}>Remove sign-in method?</H4>}
				confirmText="Remove"
				onClose={() => setShowConfirmationModal(false)}
				onSuccess={async () => {
					setShowConfirmationModal(false);

					if (postConfirmationAction === PostConfirmationAction.REMOVE_GOOGLE_SSO) {
						setIsGoogleLoading(true);
						await googleSSOUnlink();
						setIsGoogleLoading(false);
						setPostConfirmationAction(undefined);
					}
				}}
			>
				<P>
					Are you sure you want to remove this sign-in method from your account? You won't be able to sign-in using
					these details anymore.
				</P>
			</ConfirmationModal>
		</LoadErrorView>
	);
};
